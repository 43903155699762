.modal-backdrop {
	background-color: @brand-color;
}

.modal{
	z-index: 2100;
	&.animated{
		animation-duration:.5s;
	}
	&.fullscreen{
		padding-right:0 !important;
		&:before{
			display: none;
		}
		.modal-dialog{
			width: 100%;
			height: 100%;
			padding: 0;
			margin: 0;
		}
		.modal-content{
			background-color: @body-bg;
			height: 100%;
			border-radius: 0;
			overflow: auto;
			padding-top: 130px;
			.modal-header{
				width: 100%;
				border-bottom: none;
				z-index: 300;
				position: fixed;
				background-color: @brand-lighter;
				top: 0;
				button{
					border: none;
					background-color: transparent;
					font-size: 75px;
					color: fade(@brand-darker, 50%);
					line-height: 70px;
					position: absolute;
					top: 25px;
					left: 20px;
					.transition(all, .25s);
					&:hover{
						color: fade(@brand-darker, 100%);
					}
					@media(max-width: 767px){
						font-size: 45px;
						line-height: 60px;
						top: 0;
						left: 15px;
					}
				}
				.modal-title{
					margin: 0;
					@media(max-width: 767px){
						font-size: 28px;
					}
				}
			}
			.modal-body{
				padding-bottom: 80px;
				&::-webkit-scrollbar{
					display: none;
				}
				.text{
					font-size: 20px;
					line-height: 32px;
					@media(max-width:991px){
						font-size: 15px;
						line-height: 23px;
						font-weight: 400;
					}
				}
				.text-big{
					font-size: 28px;
					line-height: 1.4em;
					font-weight: @font-thin;
					@media(max-width:991px){
						font-size: 22px;
						line-height: 30px;
						font-weight: 300;
					}
				}
				img{
					@media(max-width: 767px){
						margin: 30px auto;
					}
				}
			}
		}
	}
}