.navbar{
	z-index: 2000;
	.transition(all, .25s);
	.navbar-header{
		display: inline-block;
		float: left;
		.navbar-brand{
			height: auto;
			padding: 25px 10px 10px 10px;
			&:hover{
				color: darken(@brand-color, 15%);
			}
		}
	}
	.navbar-nav{
		padding: 20px 25px;
		.transition(all, .25s);
		li{
			display: inline-block;
			margin-left: 10px;
			text-align: center;
			a{
				font-size: 15px;
				padding: 8px 0 0;
				color: @brand-color;
				width: 40px;
				height: 40px;
				border-radius: 50%;
				border: 1px solid @brand-color;
				.transition(all, .25s);
				&:hover{
					color: @brand-contrast;
					background-color: @brand-color;
				}
				&:focus{
					color: @brand-color;
					background-color: transparent;
				}
				&.flag-box{
					overflow: hidden;
					padding: 0;
					.flag-icon{
						font-size: 42px;
						margin: -1.5px 0 0 -2px;
					}
					&:hover, &:focus{
						border-color: @brand-color;
					}
				}
			}
			.flag-dropdown{
				border: none;
				background-color: transparent;
				box-shadow: none;
				min-width: 0;
				li{
					display: block;
					margin: 10px 0 0;
				}
			}
		}
	}
	&.navdown{
		.navbar-header{
			.navbar-brand{
				padding: 10px 5px 0;
				span{
					padding: 0 7px;
				}
			}
		}
		.navbar-nav{
			padding: 15px 10px 0;
			@media(max-width: 767px){
				padding: 0 10px;
			}
			li{
				margin-left: 5px;
				a{
					&.top-down{
						width: 30px;
						height: 30px;
						padding: 4px 0 0;
						font-size: 12px;
					}
					&.flag-box{
						padding: 0;
						.flag-icon{
							font-size: 32px;
							margin-left: -2px;
						}
					}
				}
				.flag-dropdown{
					li{
						margin: 5px 0 0;
					}
				}
			}
		}
	}
}

.page{
	.navbar{
		background-color: @brand-lighter; 
	}
}


/* DON'T COLLAPSE */
.navbar-collapse.collapse {
	display: block!important;
	border: none;
	box-shadow: none;
}

.navbar-nav>li, .navbar-nav {
	float: left !important;
}

.navbar-nav.navbar-right:last-child {
	margin-right: -15px !important;
}

.navbar-right {
	float: right!important;
}