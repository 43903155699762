footer{
	padding: 30px 0;
	font-size: 11px;
	background-color: @footer-bg;
	color: @footer-color;
	p{
		margin-bottom: 0;
		line-height: 20px;
	}
	a{
		color: @brand-lighter;
		&:hover{
			color: @brand-color;
		}
		&.text-brand{
			&:hover{
				color: darken(@brand-color, 25%);
			}
		}
	}
	.table-cont{
		height: 100px;
		@media(max-width:767px){
			height: auto;
		}
	}
	.sponsor{
		width: 40px;
		height: 40px;
		border-radius: 50%;
		display: inline-block;
		margin: 10px 5px 0;
		&:extend(.bg-cover);
		&1{background-image:url('../img/sponsor1.jpg');}
		&2{background-image:url('../img/sponsor2.jpg');}
		&3{background-image:url('../img/sponsor3.png');}
		&4{background-image:url('../img/sponsor4.jpg');}
		&5{background-image:url('../img/sponsor5.jpg');}
		&:hover{
			box-shadow: 0px 0px 25px rgba(254,203,110,.5);
		}
	}
}