/* POSITION */
/*======================================*/
.pos-rel	{position: relative;}

.pos-abs	{position: absolute;}

.pos-fix	{position: fixed;}



/* BOX TYPE */
/*======================================*/
.box-block	{display: block}

.box-inline	{display: inline-block}

.box-table	{
	display: table;
	height: 100%;
}

.table-cell	{
	display: table-cell;
	vertical-align: middle;
}



/* SIZES */
/*======================================*/
.full-height	{height: 100%;}
.height-lg		{height: 75%}
.height-md		{height: 50%}
.height-sm		{height: 25%}

.full-width		{width: 100%;}
.width-lg		{width: 75%}
.width-md		{width: 50%}
.width-sm		{width: 25%}



/* ALIGNMENT */
/*======================================*/
.align-top	{vertical-align: top;}
.align-mid	{vertical-align: middle;}
.align-btm	{vertical-align: bottom;}




/* BORDER */
/*======================================*/
.bord-no		{border       :0 !important}
.bord-all		{border       :1px solid @border-color-base !important}
.bord-top		{border-top   :1px solid @border-color-base !important}
.bord-btm		{border-bottom:1px solid @border-color-base !important}
.bord-lft		{border-left  :1px solid @border-color-base !important}
.bord-rgt		{border-right :1px solid @border-color-base !important}
.bord-ver		{border-top   :1px solid @border-color-base !important;border-bottom:1px solid @border-color-base !important;}
.bord-hor		{border-right :1px solid @border-color-base !important;border-left:1px solid @border-color-base !important;}

.bord-brand		{border-color: @border-color-brand !important;}
.bord-contrast		{border-color: @brand-contrast !important;}


/* SHADOW */
/*======================================*/
.text-shd		{text-shadow: @text-shadow;}
.box-shd		{box-shadow: @box-shadow;}
.text-shd-no	{text-shadow: none !important;}
.box-shd-no		{box-shadow: none !important;}



/* TEXT */
/*======================================*/

.font-base 		{font-family: @font-family-base}
.font-title		{font-family: @font-family-title}


.text-thin		{font-weight: @font-thin}
.text-lite		{font-weight: @font-lite}
.text-regular	{font-weight: @font-regular}
.text-semibold	{font-weight: @font-semibold}
.text-bold		{font-weight: @font-bold}

.ta(@val) 		{text-align: @val;}

.text-4x		{font-size: 34px;}
.text-3x		{font-size: 28px;line-height: 1.4em;}
.text-2x		{font-size: 22px;}
.text-lg		{font-size: 18px;@media(max-width: 1199px){font-size: 16px}}
.text-sm		{font-size: 12px;}
.text-xs		{font-size: 10px;}

.text-top		{vertical-align: text-top;}
.text-btm		{vertical-align: text-bottom;}

.text-overflow	{
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.text-underline	{
	text-decoration: underline;
}

.no-decoration	{
	text-decoration: none;
}

.unselectable	{
	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	.selection(transparent)
}

.text-darker {color: @brand-darker;}
.text-brand {color: @brand-color;}
.text-contrast {color: @brand-contrast;}

.text-body {
	color: @body-color;
}

a.text-body, a .text-body{
	.transition(all, .25s);
	&:hover{
		color: @brand-color;
	}
}

@media (min-width:1200px){
	.text-lg-right{.ta(right)}
	.text-lg-center{.ta(center)}
	.text-lg-left{.ta(left)}
}

@media (min-width: 992px) and (max-width:1200px){
	.text-md-right{.ta(right)}
	.text-md-center{.ta(center)}
	.text-md-left{.ta(left)}
}

@media (min-width: 768px) and (max-width:992px){
	.text-sm-right{.ta(right)}
	.text-sm-center{.ta(center)}
	.text-sm-left{.ta(left)}
}

@media (max-width: 760px){
	.text-xs-right{.ta(right)}
	.text-xs-center{.ta(center)}
	.text-xs-left{.ta(left)}
}

/* TEXTAREA */
/*======================================*/
.no-resize		{resize: none;}
.resize-hor		{resize: horizontal;}
.resize-ver		{resize: vertical;}



/* IMAGES */
/*======================================*/
.img-mar		{margin:5px}
.img-border		{
	box-shadow: 0 0 0 4px rgba(0, 0, 0, .1);
	&-light{
		box-shadow: 0 0 0 4px #fff;
	}
}
.img-xs{
	width: 32px;
	height: 32px
}
.img-sm{
	width: 46px;
	height: 46px;
}
.img-md{
	width: 64px;
	height: 64px
}
.img-lg{
	width: 96px;
	height: 96px
}
.img-xl{
	width: 120px;
	height: 120px
}

.img-icon{
	width:36px;
	height: 36px;
	display: block;
}

.img-holder img{
	max-width: 100%;
	border-radius: @border-radius-base
}

.hover:hover{
	.transition(all .25s);
	opacity: .5;
}


/* CONTENT MARGIN */
/*======================================*/
.mar-auto{
	margin-left: auto;
	margin-right: auto;
}

.mar-no{
	margin: 0 !important;
}
.mar-all{
	margin: 15px !important;
}
.mar-top{
	margin-top:15px !important;
}
.mar-btm{
	margin-bottom: 15px !important;
}
.mar-lft{
	margin-left: 15px !important;
}
.mar-rgt{
	margin-right: 15px !important;
}
.mar-hor{
	margin-left: 15px !important;
	margin-right: 15px !important;
}
.mar-ver{
	margin-top: 15px !important;
	margin-bottom: 15px !important;
}

.mar-min-all{
	margin: 5px
}
.mar-min-top{
	margin-top:5px
}
.mar-min-btm{
	margin-bottom: 5px
}
.mar-min-lft{
	margin-left: 5px
}
.mar-min-rgt{
	margin-right: 5px
}
.mar-min-hor{
	margin-left: 5px;
	margin-right: 5px
}
.mar-min-ver{
	margin-top: 5px;
	margin-bottom: 5px;
}

.mar-huge-top	{
	margin-top: @mar-huge;
}
.mar-huge-btm	{
	margin-bottom: @mar-huge;
}
.mar-huge-ver	{
	margin-top: @mar-huge;
	margin-bottom: @mar-huge;
}


/* CONTENT PADDING */
/*======================================*/
.pad-no{
	padding:0 !important
}
.pad-all{
	padding: 15px !important;
}
.pad-top{
	padding-top: 15px !important;
}
.pad-btm{
	padding-bottom: 15px !important;
}
.pad-lft{
	padding-left: 15px !important;
}
.pad-rgt{
	padding-right: 15px !important;
}
.pad-hor{
	padding-left: 15px !important;
	padding-right: 15px !important;
}
.pad-ver{
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.pad-min-all{
	padding: 5px
}
.pad-min-top{
	padding-top:5px
}
.pad-min-btm{
	padding-bottom: 5px
}
.pad-min-lft{
	padding-left: 5px
}
.pad-min-rgt{
	padding-right: 5px
}
.pad-min-hor{
	padding-left: 5px;
	padding-right: 5px
}
.pad-min-ver{
	padding-top: 5px;
	padding-bottom: 5px;
}

.pad-huge-top	{
	padding-top: @mar-huge;
}
.pad-huge-btm	{
	padding-bottom: @mar-huge;
}
.pad-huge-ver	{
	padding-top: @mar-huge;
	padding-bottom: @mar-huge;
}
.pad-huge-hor	{
	padding-left: @mar-huge;
	padding-right: @mar-huge;
}


/* BACKGROUND IMAGES */
/*======================================*/
.bg-cover{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}

.bg-contain{
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}


/* DISABLED BUTTON */
/*======================================*/
a.disabled-link, a.disabled-link:visited ,
a.disabled-link:active, a.disabled-link:hover {
	color:#aaa !important;
	cursor: default
}

/* BUTTON WIDTH */
/*======================================*/
.btn-wid-4x	{
	min-width: 10em;
}
.btn-wid-3x	{
	min-width: 9em;
}
.btn-wid-2x	{
	min-width: 8em;
}
.btn-wid-lg	{
	min-width: 7em;
}
.btn-wid-sm	{
	min-width: 6em;
}
.btn-wid-xs	{
	min-width: 4em;
}

.eq-height{
	@media (min-width:991px){
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
}

