//Variables
@font-family-base			: 'Open Sans', sans-serif;
@font-family-title			: 'Kaushan Script', cursive;
@font-size-base				: 13px;

@font-thin					: 100;
@font-lite					: 300;
@font-regular				: 400;
@font-semibold				: 600;
@font-bold					: 700;

@body-bg					: #fff3da;
@body-color					: #807a6d;

@link-color					: @brand-color;
@link-hover-color			: darken(@brand-color, 20%);

@border-color-base			: #e0d7c0;
@border-color-brand			: @brand-color;
@border-radius-base			: 0px;

@grid-gutter-width			: 30px;

@selection-color			: @brand-darker;

@transparent-bg				: transparent;
@navbar-bg                  : transparent;
@navbar-color               : @brand-color;
@brand-bg                   : transparent;
@brand-font-size            : 30px;
@navbar-height				: auto;

@footer-bg                  : #072d1d;
@footer-color               : #fff3da;

@text-shadow				: none;
@box-shadow					: 0px 1px 4px rgba(7,45,29,.1);

@mar-huge					: 80px;

//PALETTE
//============================================

@brand-darker				: #807a6d;
@brand-lighter				: #fff3da;
@brand-color				: #fecb6e;
@brand-contrast				: #072d1d;



// COMPONENT
//============================================
@light-bg                   : #ffffff;
@gray-bg                    : #f3f3f3;
@dark-bg					: #212121;

@primary-bg                 : #5fa2dd;
@info-bg                    : #4ebcda;
@success-bg                 : #89c746;
@warning-bg                 : #ffb400;
@danger-bg                  : #e80000;

@text-muted                 : lighten(@body-color,25%);

@light-color                : #212121;
@gray-color                 : #212121;
@dark-color					: #ffffff;

@primary-color              : #ffffff;
@info-color                 : #ffffff;
@success-color              : #ffffff;
@warning-color              : #ffffff;
@danger-color               : #ffffff;

@state-active-bg            : @brand-color;
@state-active-color         : #ffffff;

// OVERWRITE BOOTSTRAP COMPONENT
//============================================

//Disable components that you do not use.
@table                      : true;
@form-control               : true;
@nav-pills                  : true;
@list-group                 : true;
@label                      : true;
@badge                      : true;
@breadcrumb                 : true;
@alert                      : true;
@modal                      : true;
@tooltip                    : true;
@popover                    : true;
@dropdown                   : true;
@well                       : true;
@progressbar                : true;
@pager                      : true;
@pagination                 : true;
@carousel                   : true;
@accordion                  : true;
@tabs                       : true;
@bg                         : true;
@txt                        : true;


@buttons                    : true;
@include-btn-hover          : true;
@include-btn-active         : true;
@include-btn-labeled        : true;
@include-btn-circle         : true;
@include-btn-icon           : true;
@include-btn-rounded        : true;



@tooltip-bg                 : @brand-darker;
@state-success              : darken(@success-bg,20%);
@state-warning              : darken(@warning-bg,20%);
@state-danger               : darken(@danger-bg,20%);



@panels                     : true;
@panel-heading-height       : 50px;
@panel-w-tabs               : true;
@panel-w-progressbar        : true;
@panel-w-pagination         : true;
@panel-w-pager              : true;
@panel-w-btn                : true;

@nav-tabs-active-link-hover-bg: #ffffff;




.transform(@transform){
	-webkit-transform: @transform;
	-ms-transform: @transform;
	transform: @transform;
}

.transition(@transition) {
	-webkit-transition: @transition;
	transition: @transition;
}
.transition(@a;@b;...) {
	@transition :  ~`"@{arguments}".replace(/[\[\]]/g, '')`;
	-webkit-transition: @transition;
	transition: @transition;
}

.selection(@color){
	::selection {
		background-color: @color;
		color: @brand-color;
	}
	::-moz-selection {
		background-color: @color;
		color: @brand-color;
	}
}
.selection(@selection-color);