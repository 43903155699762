html, body{
	-ms-overflow-style: scrollbar;
	min-height: 100%;
}

body{
	font-family: @font-family-base;
	font-size: @font-size-base;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased !important;
	quotes:'\201C' '\201D' '\2018' '\2019';
	width: 100%;
	background-color: @body-bg;
	color: @body-color;
	text-align: center;
	.transition(all, .25s);
}

h1, .h1 {
	font-size: 36px;
	@media(max-width: 991px){
		font-size: 32px;
	}
}
h2, .h2 {font-size: 30px;
	@media(max-width: 991px){
		font-size: 27px;
	}
}
h3, .h3 {font-size: 24px; margin-bottom: 0;
	@media(max-width: 991px){
		font-size: 20px;
	}
}
h4, .h4 {font-size: 20px;margin-bottom: 0;
	@media(max-width: 991px){
		font-size: 18px;
	}
}
h5, .h5 {font-size: 15px;
	@media(max-width: 991px){
		font-size: 14px;
	}
}
h6, .h6 {font-size: 12px;
	@media(max-width: 991px){
		font-size: 10px;
	}
}

h1, h2, h3, h4, h5, h6, 
.h1, .h2, .h3, .h4, .h5, .h6 {
	font-weight: @font-bold;
	color: @brand-darker;
	line-height: 1.42857143;
}

a{
	.transition(all, .25s);
}

/* CUSTOM LAYOUT */
/*======================================*/

.animate-in {
	animation: fadeIn .5s ease-in;
}

.animate-out {
	transition: opacity .5s;
	opacity: 0;
}

.box-table{
	@media(max-width:767px){
		display: block;
		img{
			margin: 30px 0;
		}
	}
}

@keyframes fadeIn {
	from { opacity: 0; }
	to { opacity: 1; }
}

.tooltip{
	&.top{
		.tooltip-arrow{
			border-top-color: @brand-color;
		}
	}
	&.left{
		.tooltip-arrow{
			border-left-color: @brand-color;
		}
	}
	&.bottom{
		.tooltip-arrow{
			border-bottom-color: @brand-color;
		}
	}
	.tooltip-inner{
		background: @brand-color;
		color: @brand-contrast;
		padding: 5px 15px;
		border-radius: 1px;
	}
}

.fluid-wrap{
	width: 100%;
	.box-table{
		width: 100%;
	}
}

/* CAROUSEL HOMEPAGE */
#cb-slider{
	width: 100%;
	.carousel-inner{
		height: 100%;
		.item{
			width: 100%;
			height: 100%;
			&:extend(.bg-cover);
			background-attachment: fixed;
			&-1{background-image:url('../img/home_1.jpg');}
			&-2{background-image:url('../img/home_2.jpg');}
			&-3{background-image:url('../img/home_3.jpg');}
			&-4{background-image:url('../img/home_4.jpg');}
			&-5{background-image:url('../img/home_5.jpg');}
		}
	}
	.overlay{
		position: relative;
		width: 100%;
		height: 100%;
		background-color: fade(@brand-contrast, 60%);
		z-index: 1000;
		#top-title, #top-subtitle{
			font-family: @font-family-title;
			font-size: 120px;
			font-weight: normal;
			color: @brand-color;
			text-shadow: 0 1.5px 0px rgba(7, 45, 29, .75);
			@media(max-width:767px){
				font-size: 50px;
			}
		}
		#top-subtitle{
			font-size: 22px;
		}
		#top-divider{
			margin: -5px auto 15px;
			.top-element{
				display: inline-block;
				background-color: @brand-color;
				text-shadow: 0 1.5px 0px rgba(7, 45, 29, .75);
			}
			.top-line{
				width: 220px;
				height: 1px;
				margin: 0 7px 4px;
				@media(max-width:767px){
					width: 60px;
				}
			}
			.top-circle{
				width: 10px;
				height: 10px;
				border-radius: 50%;
			}
		}
		.box-table{
			@media(max-width:767px){
				display: table;
			}
		}
	}
}

/* DOWN BUTTON */
#top-nav{
	position: absolute;
	bottom: 40px;
	width: 100%;
	height: 40px;
	text-align:center;
	.top-down{
		border: 1px solid @brand-color;
		display:inline-block;
		font-size:15px;
		margin:0 5px;
		color: @brand-color;
		border-radius:50%;
		width:40px;
		height:40px;
		padding:8px 0 0;
		.transition(all, .25s);
		&:hover{
			color: @brand-contrast;
			background: @brand-color;
		}
		&:focus{
			color: @brand-color;
			background: transparent;
		}
	}
}


/* CASALE */
.inner-section{
	padding: 140px 0 180px;
	margin: 0 auto;
	display: table;
	@media(max-width:991px){
		padding: 100px 0 140px;
	}
}

.title{
	font-size: 70px;
	font-weight: normal;
	margin-bottom: 35px;
	color: @brand-contrast;
	font-family: @font-family-title;
	@media(max-width:991px){
		font-size: 55px;
	}
	@media(max-width:767px){
		font-size: 40px;
	}
}
.text{
	font-size: 35px;
	line-height: 55px;
	font-weight: @font-lite;
	@media(max-width:991px){
		font-size: 25px;
		line-height: 35px;
	}
	@media(max-width:767px){
		font-size: 20px;
		line-height: 30px;
	}
}

/* AMBIENTI */
.outer-section{
	height: auto;
	position: relative;
	// margin: 0 -15px;
}

.para-sec{
	padding-left: 0 !important;
	padding-right: 0 !important;
	a.big-box{
		display: block;
		width: 100%;
		height: 100%;
		.transition(all, .25s);	
		&:before, &:after{
			position: absolute;
			content: '';
			opacity: 0;
			z-index: 1300;
			transition: opacity 0.55s, transform 0.55s;
		}
		&:before{
			top: 50px;
			right: 30px;
			bottom: 50px;
			left: 30px;
			border-top: 1px solid @brand-lighter;
			border-bottom: 1px solid @brand-lighter;
			transform: scale(0,1);
			transform-origin: 0 0;
		}
		&:after{
			top: 30px;
			right: 50px;
			bottom: 30px;
			left: 50px;
			border-right: 1px solid @brand-lighter;
			border-left: 1px solid @brand-lighter;
			transform: scale(1,0);
			transform-origin: 100% 0;
		}
		.para{
			width: 100%;
			min-height: 300px;
			display: block;
			position: absolute;
			&:extend(.bg-cover);
			background-attachment: scroll;
			.transition(all, .55s);
			&.amb-1{background-image:url('../img/ambiente1.jpg');}
			&.amb-2{background-image:url('../img/ambiente2.jpg');}
			&.amb-3{background-image:url('../img/ambiente3.jpg');}
			&.din-1{background-image:url('../img/dintorni1.jpg');}
			&.din-2{background-image:url('../img/dintorni2.jpg');}
		}
		.over-amb{
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			z-index: 1250;
			padding: 3em;
			.transition(all, .35s);
			&-1{background:rgba(117,106,86,.7);}
			&-2{background:rgba(147,114,47,.7);}
			&-3{background:rgba(154,165,101,.7);}
			&.over-din{
				&-1{background:rgba(213,181,121,.7);}
				&-2{background:rgba(230,207,159,.7);}
				.title{
					margin-top: 27%;
					color: @brand-contrast;
					.transition(all, .35s);
					@media(max-width:1199px){
						margin-top: 20%;
					}
					@media(max-width:767px){
						margin-top: 11%;
					}
					@media(max-width:430px){
						margin-top: 25%;
					}
				}
				p{
					color: @brand-contrast;
					font-size: 13px;
					padding: 0.5em 4em;
				}
			}
			.title{
				margin-top: 45%;
				display: block;
				font-size: 40px;
				color: @brand-lighter;
				.transition(all, .35s);
				@media(max-width:1199px){
					margin-top: 35%;
				}
				@media(max-width:991px){
					margin-top: 27%;
					font-size: 28px;
				}
				@media(max-width:767px){
					margin-top: 11%;
					font-size: 40px;
				}
				@media(max-width:430px){
					margin-top: 25%;
				}
			}
			p{
				color: @brand-lighter;
				font-size: 12px;
				padding: 0.5em 2em;
				text-transform: none;
				opacity: 0;
				transform: translate3d(0,-10px,0);
				transition: opacity 0.35s, transform 0.35s;
				@media(max-width:991px){
					font-size: 11px;
					padding: 0.5em 1.4em;
				}
				@media(max-width:767px){
					font-size: 14px;
					padding: 0.5em 2em;
				}
			}
		}
	}
	&:hover{
		a{
			&:after, &:before{
				opacity: 1;
				transform: scale(1);
			}
			&:after{
				transition-delay: 0.15s;
			}
			.para{
				background-position: left top;
			}
			.over-amb{
				.title{
					transform: translate3d(0,-20px,0);
				}
				p{
					opacity: 1;
					transform: translate3d(0,-50px,0);
					transition-delay: 0.15s;
				}
			}
			@media(max-width: 767px){
				&:after, &:before{
					opacity: 0;
				}
				.para{
					background-position: center center;
				}
				.over-amb{
					.title{
						transform: translate3d(0,0,0);
					}
					p{
						opacity: 0;
					}
				}
			}
		}
	}
}

/* LE STANZE */
.circle{
	width: 280px;
	height: 280px;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	display: block;
	margin: 95px auto 0;
	@media(max-width:991px){
		width: 220px;
		height: 220px;
	}
	@media(max-width:767px){
		margin: 35px auto 0;
	}
	.room{
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1301;
		&:extend(.bg-cover);
		background-attachment: scroll;
		&-1{background-image:url('../img/room_1.jpg');}
		&-2{background-image:url('../img/room_2.jpg');}
		&-3{background-image:url('../img/room_3.jpg');}
		&-amb-1{background-image:url('../img/ambiente1.jpg');}
		&-amb-2{background-image:url('../img/ambiente2.jpg');}
		&-amb-3{background-image:url('../img/ambiente3.jpg');}
		&-din-1{background-image:url('../img/dintorni1.jpg');}
		&-din-2{background-image:url('../img/dintorni2.jpg');}
	}
	.over-room{
		background-color: fade(@brand-lighter, 68%);
		position: absolute;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		padding: 3em;
		z-index: 1302;
		.transition(all, .25s);
		img{
			width: 40%;
			height: 40%;
			opacity: 0;
			transform: translate3d(0,20px,0);
			transition: opacity 0.35s, transform 0.35s;
		}
		.title{
			font-size: 30px;
			margin: 34% auto 0;
			display: block;
			.transition(all, .25s);
		}
	}
	&:hover{
		.over-room{
			background-color: fade(@brand-lighter, 40%);
			.title{
				transform: translate3d(0,-35px,0);
				text-shadow:0 1px 0 @brand-lighter;
			}
			img{
				opacity: 1;
				transform: translate3d(0,-20px,0);
				transition-delay: 0.15s;
			}
		}
	}
}


.inner-link{
	text-align: center;
	margin-bottom: 60px;
	.title{
		font-size: 50px;
	}
	.circle{
		margin-top: 15px;
		width: 180px;
		height: 180px;
		.title{
			font-size: 20px;
		}
	}
	.room-din-1 + .over-room .title{
		margin: 23% auto 0;
	}
}

/* GALLERY */
.para-video{
	video{
		max-width: 100%;
		height: auto;
		position: relative !important;
	}
	@media(max-width:767px){
		display: none;
	}
}

.over-video{
	position: absolute;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	padding: 3em;
	z-index: 1302;
	background-image: url('../img/pattern.png');
	background-position: 0 0;
	background-repeat: repeat;
	@media(max-width:767px){
		background-color: @brand-contrast;
	}
	.para-text{
		width: 100%;
		margin: 140px auto;
		text-align: center;
		font-size: 60px;
		color: @brand-lighter;
		@media(max-width:1199px){
			margin: 50px auto;
		}
		@media(max-width:767px){
			font-size: 30px;
		}
	}
}

.img-preview{
	height: 230px;
	width: 100%;
	.transition(all,.25s);
	&:hover{
		opacity: 0.7;
	}
}

/*LIGHT GALLERY */
.lg-backdrop{
	background-color: fade(@brand-darker, 85%);
	z-index: 3900;
}
.lg-outer{
	z-index: 4000;
	.lg-item{
		display: block;
	}

}

/* CONTATTI */
#contatti{
	h5{
		color: @brand-contrast;
		font-weight: @font-semibold;
	}
	p{
		font-size: 12px;
	}
	ul.info{
		padding: 15px 0;
		li{
			list-style: none;
			padding-bottom: 10px;
			color: @brand-contrast;
			i.fa{
				padding-right: 5px;
				color: @brand-contrast;
				width: 20px;
				text-align: center;
			}
			a{
				color: @brand-contrast;
				&:hover{
					color: @brand-color;
				}
			}
		}
	}
}

.form-group{
	margin-bottom: 0;
	.form-control{
		border-radius: 0;
		background-color: transparent;
		border: 1px solid #e0d7c0;
		color: @brand-darker;
		font-size: 12px;
		box-shadow: none;
		margin-bottom: 5px;
		padding: 12.5px;
		height: 44px;
		&:focus{
			border: 1px solid lighten(#e0d7c0, 4%);
			outline: 0;
			box-shadow: inset 0px 0px 7px lighten(#e0d7c0, 4%);
		}
	}
	textarea{
		&.form-control{
			height: auto;
		}
	}
}



/* Mappa */
#map{
	.para_sec{
		height:330px !important;
		background: @brand-color;
	}
	#map-canvas{
		width: 100%;
		height: 100%;
	}
}


/* PAGINE INTERNE */
.page{
	padding-top: 85px;
	text-align: left;
	.title{
		margin: 0;
	}
	.text{
		font-size: 20px;
		line-height: 32px;
	}
}


/* CAROUSEL FADE */
.carousel-fade .carousel-inner .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .carousel-inner .active {
  opacity: 1;
}

.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}

.carousel-fade .carousel-control {
  z-index: 2;
}

@media all and (transform-3d), (-webkit-transform-3d) {
	.carousel-fade .carousel-inner > .item.next,
	.carousel-fade .carousel-inner > .item.active.right {
	  opacity: 0;
	  -webkit-transform: translate3d(0, 0, 0);
			  transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.prev,
	.carousel-fade .carousel-inner > .item.active.left {
	  opacity: 0;
	  -webkit-transform: translate3d(0, 0, 0);
			  transform: translate3d(0, 0, 0);
	}
	.carousel-fade .carousel-inner > .item.next.left,
	.carousel-fade .carousel-inner > .item.prev.right,
	.carousel-fade .carousel-inner > .item.active {
	  opacity: 1;
	  -webkit-transform: translate3d(0, 0, 0);
			  transform: translate3d(0, 0, 0);
	}
}


