/* Button Efx */
/*---------------------------------*/
.btn-efx(@bg-color; @bord-color;){
	background-color: @bg-color;
	border: 1px solid @bord-color;
	color: #fff !important;
	&:hover, &:focus, &:active{
		background-color: darken(@bg-color, 10%); 
		border: 1px solid darken(@bord-color, 10%);
	}
}


.btn-darker{
	.btn-efx(@brand-darker; @brand-darker; );
}
.btn-brand{
	.btn-efx(@brand-color; @brand-color; );
}
.btn-contrast{
	.btn-efx(@brand-contrast; @brand-contrast; );
}


/* Buttons */
/*---------------------------------*/

.btn{
	padding: 12px 34px;
}

.btn-yel{
	.btn-efx(transparent; @brand-lighter;);
	color: @brand-lighter !important;
	text-transform: uppercase;
	&:hover{
		background-color: @brand-lighter;
		color: @brand-contrast !important;
	}
}

.btn-cb{
	.btn-efx(transparent; #e0d7c0;);
	color: @brand-darker !important;
	text-transform: uppercase;
	&:hover{
		background-color: @brand-darker;
		color: @brand-lighter !important;
		border: 1px solid @brand-darker;
	}
}

