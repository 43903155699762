#loader-wrapper{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000;
	#loader{
		display: block;
		position: relative;
		left: 50%;
		top: 50%;
		width: 40px;
		height: 40px;
		margin: -20px 0 0 -20px;
		border-radius: 100%;
		background-color: @brand-contrast;
		animation: sk-scaleout 1.0s infinite ease-in-out;
		z-index: 1001;
	}
	.loader-section{
		position: fixed;
		top: 0;
		width: 51%;
		height: 100%;
		background: @brand-color;
		z-index: 1000;
		transform: translateX(0);
		&.section-left{
			left: 0;
		}
		&.section-right{
			right: 0;
		}
	}
}

.loaded{
	#loader-wrapper{
		visibility: hidden;
		transform: translateY(-100%);
		transition: all 0.3s 1s ease-out;
		#loader{
			opacity: 0;
			transition: all 0.3s ease-out;
		}
		.loader-section{
			&.section-left{
				transform: translateX(-100%);
				transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			}
			&.section-right{
				transform: translateX(100%);
				transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
			}
		}
	}
}

.no-js #loader-wrapper {
	display: none;
}