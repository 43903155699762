::-moz-selection {
  background-color: #807a6d;
  color: #fecb6e;
}
::selection {
  background-color: #807a6d;
  color: #fecb6e;
}
::-moz-selection {
  background-color: #807a6d;
  color: #fecb6e;
}
/* BOOTSTRAP'S COMPONENTS */
/*=====================================
.container{
	@media(max-width: 991px){
		width: 100%;
	}
}=*/
.collapse {
  display: none;
}
.jumbotron {
  padding: 30px;
  background-color: #f7f7f8;
}
.navbar-toggle .icon-bar {
  background-color: #aaa;
}
.canvas-responsive {
  max-width: 100%;
}
a {
  text-decoration: none;
  color: #fecb6e;
  outline: 0;
}
a:hover,
a:focus {
  text-decoration: none;
  color: #fda709;
  outline: 0 !important;
}
button,
button:focus {
  outline: 0 !important;
}
code {
  background-color: #e7e3f2;
  color: #ff0000;
  padding: 2px 7px;
  border-radius: 2px;
  font-size: 97%;
}
kbd {
  border-radius: 2px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
label {
  font-weight: normal;
}
legend {
  padding: 10px;
  font-size: 18px;
  font-weight: 700;
  border-color: #eee;
}
mark,
.mark {
  background-color: #ffe3a2;
  color: #563c00;
  padding: 0.1em;
}
/* HORIZONTAL RULE */
/*======================================*/
hr {
  border-color: rgba(0, 0, 0, 0.05);
}
.hr-wide {
  margin-left: -15px;
  margin-right: -15px;
}
.hr-xs {
  margin: 5px 0;
}
.hr-sm {
  margin: 10px 0;
}
[class^="col-"]:not(.pad-no) {
  padding-left: 15px;
  padding-right: 15px;
}
/* MEDIA */
/*======================================*/
.media-block .media-left {
  display: block;
  float: left;
}
.media-block .media-right {
  float: right;
}
.media-block .media-body {
  display: block;
  overflow: hidden;
  width: auto;
}
.middle .media-left,
.middle .media-right,
.middle .media-body {
  vertical-align: middle;
}
/* THUMBNAIL */
/*======================================*/
.thumbnail {
  border-radius: 0px;
  border-color: #e0d7c0;
}
/* TABLE */
/*======================================*/
.container,
.modal-body {
  /*.table-striped > tbody > tr:nth-child(2n+1){
			background-color: lighten(@gray-bg, 6%);
		}
		.table-hover > tbody > tr:hover{
			background-color: lighten(@gray-bg,4%);
		}*/
}
.container .table th,
.modal-body .table th {
  color: #fecb6e;
  font-weight: 700;
  border-bottom: 1px solid rgba(0, 0, 0, 0.14);
}
.container .table tr:first-child td,
.modal-body .table tr:first-child td {
  border-top: none;
}
.container .table td,
.modal-body .table td {
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}
.container .table.table-vcenter th,
.modal-body .table.table-vcenter th,
.container .table.table-vcenter td,
.modal-body .table.table-vcenter td {
  vertical-align: middle;
}
.container .table .min-width,
.modal-body .table .min-width {
  width: 1%;
  white-space: nowrap;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.container .table .min-width.no-pad-hor,
.modal-body .table .min-width.no-pad-hor {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.container .table .min-width.no-pad-ver,
.modal-body .table .min-width.no-pad-ver {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.container .table.table-cart td,
.modal-body .table.table-cart td {
  border-top: none;
}
.container .table-bordered td,
.modal-body .table-bordered td,
.container .table-bordered th,
.modal-body .table-bordered th {
  border-color: rgba(0, 0, 0, 0.11);
}
/* FORM CONTROL  */
/*======================================*/
.form-control {
  font-size: 13px;
  height: 37px;
  border-radius: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #e0d7c0;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}
.form-control:focus {
  border-color: #fecb6e;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-transition-duration: .5s;
          transition-duration: .5s;
}
.form-control:focus-feedback {
  z-index: 10;
}
.form-control.huge-input {
  padding: 10px 20px;
  font-size: 2.5em;
  text-align: center;
  display: inline;
  font-weight: 300;
}
.has-error .form-control,
.has-warning .form-control,
.has-success .form-control {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.fa.form-control-feedback {
  line-height: 25px;
}
.input-group-addon {
  border: 1px solid #e1e5ea;
  background-color: transparent;
  border-radius: 0px;
  min-width: 45px;
}
/* NAV PILLS */
/*======================================*/
.nav-pills > li > a {
  border-radius: 0px;
}
.nav-pills > .active > a,
.nav-pills > .active > a:hover,
.nav-pills > .active > a:focus {
  background-color: #5fa2dd;
}
.nav-tabs > li > a {
  border-radius: 0px 0px 0 0;
}
/* LIST GROUP */
/*======================================*/
.list-group.bg-trans .list-group-item:not(.active):not(.disabled) {
  background-color: transparent;
  border-color: transparent;
  color: inherit;
}
.list-group.bg-trans .list-group-item .disabled {
  opacity: 0.5;
}
.list-group.bg-trans a.list-group-item:hover:not(.active) {
  background-color: rgba(0, 0, 0, 0.05);
}
.list-group.bord-no .list-group-item {
  border-color: transparent;
}
.list-group .list-divider {
  display: block;
}
.list-group-item {
  border-color: #e0d7c0;
}
.list-group-item-heading {
  margin-top: 5px;
}
.list-group-item:first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.list-group-item:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.list-group-item .list-group-item.disabled,
.list-group-item .list-group-item.disabled:hover,
.list-group-item .list-group-item.disabled:focus {
  background-color: rgba(0, 0, 0, 0.07);
  border-color: transparent;
}
.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #fecb6e;
  border-color: #fecb6e;
  color: #ffffff;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:hover .list-group-item-text,
.list-group-item.active:focus .list-group-item-text {
  color: #ffffff;
}
a.list-group-item:hover,
a.list-group-item:focus {
  background-color: rgba(0, 0, 0, 0.05);
}
.list-group-item-primary,
a.list-group-item-primary {
  background-color: #7cb3e3;
  border-color: transparent;
  color: #ffffff;
}
a.list-group-item-primary:hover,
a.list-group-item-primary:focus {
  background-color: #89bae6;
  color: #ffffff;
}
.list-group-item-info,
a.list-group-item-info {
  background-color: #6cc7e0;
  border-color: transparent;
  color: #ffffff;
}
a.list-group-item-info:hover,
a.list-group-item-info:focus {
  background-color: #78cce3;
  color: #ffffff;
}
.list-group-item-success,
a.list-group-item-success {
  background-color: #9acf61;
  border-color: transparent;
  color: #ffffff;
}
a.list-group-item-success:hover,
a.list-group-item-success:focus {
  background-color: #a2d36d;
  color: #ffffff;
}
.list-group-item-warning,
a.list-group-item-warning {
  background-color: #ffbf24;
  border-color: transparent;
  color: #ffffff;
}
a.list-group-item-warning:hover,
a.list-group-item-warning:focus {
  background-color: #ffc333;
  color: #ffffff;
}
.list-group-item-danger,
a.list-group-item-danger {
  background-color: #ff0d0d;
  border-color: transparent;
  color: #ffffff;
}
a.list-group-item-danger:hover,
a.list-group-item-danger:focus {
  background-color: #ff1c1c;
  color: #ffffff;
}
/* LABEL */
/*======================================*/
.label {
  font-weight: normal;
  border-radius: 0.1em;
  font-size: 85%;
}
.label:empty {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: sub;
}
.label.label-fw {
  margin-right: 0.5em;
}
.labels .label {
  display: inline-block;
  margin-right: 3px;
  margin-bottom: 3px;
}
.label-md {
  font-size: 100%;
}
.label-table {
  display: inline-block;
  width: 80%;
  min-width: 8ex;
  font-size: 1em;
  max-width: 100px;
  padding: 5px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.label-default {
  background-color: #e3e8ee;
  color: #333;
}
.label-primary {
  background-color: #5fa2dd;
}
.label-info {
  background-color: #4ebcda;
}
.label-success {
  background-color: #89c746;
}
.label-warning {
  background-color: #ffb400;
}
.label-danger {
  background-color: #e80000;
}
/* BREADCRUMB */
/*======================================*/
.breadcrumb {
  border-radius: 0;
  background-color: transparent;
  margin-bottom: 10px;
  padding: 0;
}
.breadcrumb li,
.breadcrumb li a {
  font-size: 10px;
  color: #bcb8b0;
}
.breadcrumb li:hover,
.breadcrumb li a:hover {
  color: #807a6d;
}
.breadcrumb li:last-child {
  color: #fecb6e;
  font-weight: bold;
}
.breadcrumb li:last-child a {
  color: #fecb6e;
}
.breadcrumb > li + li:before {
  content: "\f105";
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
}
@media (min-width: 992px) {
  .breadcrumb {
    padding: 0;
  }
}
/* ALERT */
/*======================================*/
.alert {
  border-radius: 0px;
  border: 0;
  padding: 15px 3em 15px 15px;
  position: relative;
}
.alert .alert-link {
  text-decoration: underline;
  font-weight: normal;
}
.alert .alert-link:hover {
  text-decoration: underline;
}
.alert .close {
  text-shadow: none;
  opacity: .3;
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
}
.alert button.close {
  padding: 1px;
  border-radius: 20px;
  -webkit-transition: all, 0.3s;
  transition: all, 0.3s;
}
.alert button.close > span:not(.sr-only) {
  display: block;
  width: 1em;
  height: 1em;
  line-height: 0.8em;
}
.alert button.close:hover {
  opacity: .55;
}
.alert button.close:active {
  opacity: 0.3;
}
.alert-primary {
  background-color: #6daae0;
  border-color: transparent;
  color: #ffffff;
}
.alert-primary .close,
.alert-primary .alert-link {
  color: #ffffff;
}
.alert-info {
  background-color: #5ec2dd;
  border-color: transparent;
  color: #ffffff;
}
.alert-info .close,
.alert-info .alert-link {
  color: #ffffff;
}
.alert-success {
  background-color: #94cc57;
  border-color: transparent;
  color: #ffffff;
}
.alert-success .close,
.alert-success .alert-link {
  color: #ffffff;
}
.alert-warning {
  background-color: #ffbb17;
  border-color: transparent;
  color: #ffffff;
}
.alert-warning .close,
.alert-warning .alert-link {
  color: #ffffff;
}
.alert-danger {
  background-color: #ea1717;
  border-color: transparent;
  color: #ffffff;
}
.alert-danger .close,
.alert-danger .alert-link {
  color: #ffffff;
}
/* MODAL */
/*======================================*/
.modal {
  text-align: center;
}
.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  margin-left: -1em;
  vertical-align: middle;
}
.modal.fade:not(.animated) .modal-dialog {
  opacity: 0;
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
  -webkit-transition: all .5s linear .5s;
  transition: all .5s linear .5s;
}
.modal.fade.in:not(.animated) .modal-dialog {
  opacity: 1;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
  -webkit-transition: all .5s;
  transition: all .5s;
}
.bootbox.modal.in:not(.fade) {
  opacity: 1;
  -webkit-transition: opacity, 0.5s;
  transition: opacity, 0.5s;
}
.bootbox.modal:not(.fade) {
  opacity: 0;
  -webkit-transition: opacity .5s linear .5s;
  transition: opacity .5s linear .5s;
}
.modal-dialog {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  margin-top: -1%;
  min-width: 90%;
}
.modal-dialog.animated {
  -webkit-animation-duration: .7s;
  animation-duration: .7s;
}
.modal-header {
  padding: 15px;
  position: relative;
}
.modal-header:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 15px;
  right: 15px;
  height: 1px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.modal-footer {
  background-color: rgba(0, 0, 0, 0.025);
  color: #5c7174;
  border-color: rgba(0, 0, 0, 0.04);
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.modal-title {
  font-size: 18px;
}
.modal-body {
  min-height: 90px;
}
.modal-body .close,
.modal-header .close {
  top: 15px;
  right: 10px;
  left: auto;
  position: absolute;
  background-color: transparent !important;
}
.modal-content {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0px;
}
.modal-footer {
  padding: 10px 15px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.modal-backdrop.in {
  opacity: 0.75;
}
@media (min-width: 768px) {
  .modal-dialog {
    min-width: 0;
  }
  .modal-dialog-msg {
    min-width: 48%;
  }
}
/* TOOLTIP */
/*======================================*/
.tooltip {
  z-index: 999999;
}
.tooltip-inner {
  font-size: 13px;
  border-radius: 0px;
  padding: 5px 10px;
  background-color: #807a6d;
}
.tooltip.top .tooltip-arrow,
.tooltip.top-left .tooltip-arrow,
.tooltip.top-right .tooltip-arrow {
  border-top-color: #807a6d;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #807a6d;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #807a6d;
}
.tooltip.bottom .tooltip-arrow,
.tooltip.bottom-left .tooltip-arrow,
.tooltip.bottom-right .tooltip-arrow {
  border-bottom-color: #807a6d;
}
/* POPOVER */
/*======================================*/
.popover {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  border-radius: 0px;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
.popover-title {
  background-color: #f5f6f7;
  color: #5c7174;
  font-weight: 300;
  border-radius: 0px 0px 0 0;
}
.popover > .arrow {
  border-width: 9px;
}
.popover > .arrow:after {
  border-width: 9px;
}
.popover.left > .arrow {
  right: -9px;
  margin-top: -9px;
}
.popover.left > .arrow:after {
  bottom: -9px;
}
.popover.right > .arrow {
  left: -9px;
  margin-top: -9px;
}
.popover.right > .arrow:after {
  bottom: -9px;
}
.popover.top > .arrow {
  bottom: -9px;
}
.popover.top > .arrow:after {
  margin-left: -9px;
}
.popover.bottom > .arrow {
  top: -9px;
}
.popover.bottom > .arrow:after {
  margin-left: -9px;
}
.popover-content {
  padding: 10px 15px 20px;
}
/* BADGE */
/*======================================*/
.container .badge {
  font-weight: normal;
  color: #fff;
}
.badge:empty.badge-icon {
  display: inline-block;
  width: 0.7em;
  height: 0.7em;
  padding: 0;
  min-width: 5px;
  margin: .5em;
  border-radius: 50%;
}
.badge.badge-fw,
.badge:empty.badge-fw {
  margin-right: 1em;
}
.badge-default {
  background-color: #e3e8ee;
  color: #333;
}
.badge-primary {
  background-color: #5fa2dd;
}
.badge-info {
  background-color: #4ebcda;
}
.badge-success {
  background-color: #89c746;
}
.badge-warning {
  background-color: #ffb400;
}
.badge-danger {
  background-color: #e80000;
}
/* DROPDOWN */
/*======================================*/
.dropdown-header {
  padding: 5px 20px 5px 10px;
}
.dropdown-toggle > .dropdown-caret {
  padding-left: 0.5em;
}
.dropdown-toggle.dropdown-toggle-icon > i {
  padding: 0 .25em;
}
.dropdown-menu {
  font-size: 13px;
  border-radius: 0px;
  -webkit-box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 2px 1px 0 rgba(0, 0, 0, 0.15);
  margin: 1px 0 0 0;
  padding: 0;
  border: 1px solid #e0d7c0;
}
.dropdown-menu-right {
  left: auto;
  right: 0;
}
.dropup .dropdown-menu {
  -webkit-box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.15);
          box-shadow: 0 -2px 1px 0 rgba(0, 0, 0, 0.15);
}
.dropdown-menu > li > a {
  padding: 5px 10px;
}
.dropdown-menu > li > a:hover {
  background-color: #fecb6e;
  color: #ffffff !important;
}
.dropdown-menu.with-arrow:before,
.dropdown-menu.with-arrow:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  top: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.dropdown-menu.with-arrow:before {
  border-bottom: 7px solid #cfc19e;
  margin: -7px 0 0 15px;
}
.dropdown-menu.with-arrow:after {
  border-bottom: 7px solid #fff;
  margin: -6px 0 0 15px;
}
.dropdown-menu-right.dropdown-menu.with-arrow:before {
  left: auto;
  right: 0;
  margin: -7px 25px 0 0;
}
.dropdown-menu-right.dropdown-menu.with-arrow:after {
  left: auto;
  right: 0;
  margin: -6px 25px 0 0;
}
.dropdown-menu.with-arrow-down {
  left: -17px;
  bottom: 23px;
}
.dropdown-menu.with-arrow-down:before,
.dropdown-menu.with-arrow-down:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  left: 0;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
}
.dropdown-menu.with-arrow-down:before {
  border-top: 7px solid #cfc19e;
  margin: -7px 0 0 15px;
}
.dropdown-menu.with-arrow-down:after {
  border-top: 7px solid #fff;
  margin: -6px 0 0 15px;
}
.dropdown-menu-right.dropdown-menu.with-arrow-down:before {
  left: auto;
  right: 0;
  margin: -7px 25px 0 0;
}
.dropdown-menu-right.dropdown-menu.with-arrow-down:after {
  left: auto;
  right: 0;
  margin: -6px 25px 0 0;
}
.dropdown-menu-sm {
  min-width: 200px;
}
.dropdown-menu-md {
  min-width: 275px;
}
.dropdown-menu-lg {
  min-width: 350px;
}
.dropdown.open > .btn,
.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 1px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 3px 1px rgba(0, 0, 0, 0.3);
}
.dropdown.open > .btn.no-focus,
.btn-group.open .dropdown-toggle.no-focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* WELL */
/*======================================*/
.well {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.well-xs {
  padding: 5px;
}
/* PROGRESSBAR */
/*======================================*/
.progress {
  height: 12px;
  margin-bottom: 15px;
  border-radius: 0px;
  -webkit-box-shadow: none;
          box-shadow: none;
  background-color: rgba(0, 0, 0, 0.1);
}
.progress .progress-bar {
  font-size: 9px;
  line-height: 12px;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.progress-light-base {
  background-color: #fff;
}
.progress-dark-base {
  background-color: rgba(255, 255, 255, 0.2);
}
.progress-xl {
  height: 30px;
  margin-bottom: 20px;
}
.progress-xl .progress-bar {
  font-size: 13px;
  line-height: 30px;
}
.progress-lg {
  height: 20px;
  margin-bottom: 20px;
}
.progress-lg .progress-bar {
  font-size: 13px;
  line-height: 20px;
}
.progress-md {
  height: 8px;
  margin-bottom: 5px;
}
.progress-md .progress-bar {
  font-size: 5px;
  line-height: 8px;
}
.progress-sm {
  height: 4px;
  margin-bottom: 5px;
}
.progress-sm .progress-bar {
  font-size: 0;
  line-height: 4px;
}
.progress-xs {
  height: 2px;
  margin-bottom: 10px;
}
.progress-xs .progress-bar {
  font-size: 0;
  line-height: 2px;
}
.progress-bar-light {
  background-color: #fff;
}
.progress-bar-primary {
  background-color: #5fa2dd;
}
.progress-bar-info {
  background-color: #4ebcda;
}
.progress-bar-success {
  background-color: #89c746;
}
.progress-bar-warning {
  background-color: #ffb400;
}
.progress-bar-danger {
  background-color: #e80000;
}
/*======================================*/
/* PAGER */
/*======================================*/
.pager li > a:active,
.pagination > li a:active {
  -webkit-box-shadow: inset 0 3px 1px rgba(0, 0, 0, 0.2);
          box-shadow: inset 0 3px 1px rgba(0, 0, 0, 0.2);
}
.pager li > a:hover,
.pager li > a:focus,
.pagination > li a:hover,
.pagination > li a:focus {
  background-color: #ffffff;
  border-color: #fecb6e;
  color: #fecb6e;
  -webkit-box-shadow: inset 0 0 1px #fecb6e;
          box-shadow: inset 0 0 1px #fecb6e;
  z-index: 2;
  -webkit-transition: border-color, 0.3s;
  transition: border-color, 0.3s;
}
.pager li > a,
.pager li > span {
  border-radius: 0px;
  border-color: #d8ccaf;
}
.pager.pager-rounded li > a,
.pager.pager-rounded li > span {
  border-radius: 15px;
}
.pager .disabled > a,
.pager .disabled > span,
.pager .disabled > a:hover,
.pager .disabled > span:hover,
.pager .disabled > a:focus,
.pager .disabled > span:focus,
.pagination .disabled > a,
.pagination .disabled > span,
.pagination .disabled > a:hover,
.pagination .disabled > span:hover,
.pagination .disabled > a:focus,
.pagination .disabled > span:focus {
  opacity: .7;
  border-color: #d8ccaf;
  -webkit-box-shadow: none;
          box-shadow: none;
}
/*======================================*/
/* PAGINATION */
/*======================================*/
.pagination > li > a,
.pagination > li > span {
  color: inherit;
  border-color: #d8ccaf;
  -webkit-transition: border-color, 0.3s;
  transition: border-color, 0.3s;
}
.pagination > li > span {
  cursor: default;
}
.pagination > li > span:hover {
  background-color: #ffffff;
}
.pagination > li:first-child > a,
.pagination > li:first-child span {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.pagination > li:last-child > a,
.pagination > li:last-child span {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #fecb6e;
  border-color: #fecb6e;
}
/* CAROUSEL                             */
/*======================================*/
.carousel-control.left,
.carousel-control.right {
  background-image: none;
  background-repeat: no-repeat;
  color: inherit;
}
.carousel-control,
.carousel-control:focus {
  font-size: 1em;
  text-shadow: none;
  width: auto;
  padding: 10px;
  top: 0;
  bottom: 0;
  opacity: 0;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.carousel-control.auto-hide {
  opacity: 0;
}
.carousel-control:before {
  content: '';
  display: inline-block;
  height: 100%;
  width: 0;
  vertical-align: middle;
}
.carousel-control i {
  position: relative;
  top: 0.25em;
}
.carousel:hover .carousel-control {
  opacity: 1;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}
.carousel-indicators.out {
  bottom: 0;
}
.carousel-indicators.out + .carousel-inner {
  padding-bottom: 30px;
}
.carousel-indicators.square li {
  border-radius: 1px;
}
/* BLOCKQUOTE */
/*======================================*/
.bq-sm {
  font-size: 13px;
}
.bq-open:before,
.bq-open:after {
  color: rgba(0, 0, 0, 0.3);
  content: open-quote;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 4em;
  line-height: 0.1em;
  margin: 0 0.04em;
  vertical-align: -0.4em;
}
.bq-open:after {
  content: close-quote;
}
blockquote {
  line-height: 25px;
  margin: 0 0 5px;
}
/* FORM */
/*======================================*/
.form-horizontal .control-label {
  margin-bottom: 5px;
}
.form-horizontal .control-label.text-left {
  text-align: left;
}
.form-inline .form-control.inline-block {
  width: 100%;
}
/* ACCORDION */
/*======================================*/
.panel > .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
}
.panel-group {
  margin-bottom: 35px;
}
.panel-group.accordion .panel {
  margin-bottom: 0;
}
.panel-group.accordion .panel-heading {
  border-radius: 0;
  height: auto;
}
.panel-group.accordion .panel-heading:after {
  border: 0 !important;
}
.panel-group.accordion .panel-heading,
.panel-group.accordion .panel-title {
  padding: 0;
}
.panel-group.accordion .panel-title {
  line-height: 40px;
}
.panel-group.accordion .panel-title a {
  display: block;
  color: inherit;
  text-transform: none;
  font-size: 13px;
  font-weight: 300;
  padding: 0 15px;
}
.panel-group.accordion .panel-title a:hover,
.panel-group.accordion .panel-title a:focus {
  color: inherit;
}
.panel-group.accordion .panel:first-child,
.panel-group.accordion .panel:first-child .panel-heading {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.panel-group.accordion .panel:last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  overflow: hidden;
}
/* TAB */
/*======================================*/
.tab-base {
  margin-bottom: 35px;
}
.tab-base .tab-content {
  background-color: #ffffff;
  -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  padding: 15px;
}
.tab-base .tab-content .tab-footer {
  background-color: #fff8e9;
  color: #807a6d;
  border-color: #fff5df;
  position: relative;
  margin: 0 -15px -15px -15px;
  padding: 10px 15px;
}
.tab-base .nav-tabs {
  border: 0;
}
.tab-base .nav-tabs > li:not(.active) > a {
  background-color: rgba(255, 255, 255, 0.35);
  opacity: .7;
  -webkit-transition: opacity, 0.3s;
  transition: opacity, 0.3s;
}
.tab-base .nav-tabs > li:not(.active) > a:hover {
  opacity: .9;
  background-color: rgba(255, 255, 255, 0.55);
  border-color: #fecb6e;
  border-bottom-color: transparent;
  -webkit-transition: opacity, 0.3s;
  transition: opacity, 0.3s;
}
.tab-base .nav-tabs > .active > a,
.tab-base .nav-tabs > .active a:hover,
.tab-base .nav-tabs > .active > a:focus {
  border-color: transparent;
}
.tab-base .nav-tabs.tabs-right {
  text-align: right;
}
.tab-base .nav-tabs.tabs-right > li {
  float: none;
  display: inline-block;
  margin-right: -2px;
}
.tab-footer:after {
  content: '';
  display: table;
  clear: both;
}
.nav-tabs li a {
  border-radius: 0px 0px 0 0;
}
.nav-tabs.nav-justified > li > a {
  border-radius: 0px 0px 0 0;
}
.nav-tabs.tab-right {
  text-align: right;
}
.nav-tabs.tab-right > li {
  display: inline-block;
  text-align: left;
  float: none;
}
.nav-tabs.tab-right > li > a {
  margin-right: 0;
}
.nav-tabs .label,
.nav-tabs .badge {
  margin-left: 4px;
}
.tab-stacked-left,
.tab-stacked-right {
  display: table;
  height: 100%;
  width: 100%;
}
.tab-stacked-left .nav-tabs > li,
.tab-stacked-right .nav-tabs > li {
  float: none;
  margin: 0;
}
.tab-stacked-left .nav-tabs > li > a,
.tab-stacked-right .nav-tabs > li > a {
  margin: 0 0 2px;
}
.tab-stacked-left .nav-tabs > li:last-child > a,
.tab-stacked-right .nav-tabs > li:last-child > a {
  margin-bottom: 0;
}
.tab-stacked-left .nav-tabs,
.tab-stacked-right .nav-tabs,
.tab-stacked-left .tab-content,
.tab-stacked-right .tab-content {
  display: table-cell;
  vertical-align: top;
}
.tab-stacked-left .tab-content,
.tab-stacked-right .tab-content {
  overflow: hidden;
}
.tab-stacked-left .tab-content.tab-select,
.tab-stacked-right .tab-content.tab-select {
  overflow: visible;
}
.tab-stacked-left .nav-tabs {
  width: 1%;
  border: 0;
}
.tab-stacked-left .nav-tabs > li a {
  border-right-color: transparent;
  border-radius: 0px 0 0 0px;
}
.tab-stacked-left .nav-tabs > .active > a:hover,
.tab-stacked-left .nav-tabs > .active > a:focus {
  border-right-color: transparent;
}
.tab-stacked-left.tab-base .nav-tabs > li:not(.active) a:hover {
  border-color: #fecb6e;
  border-right-color: transparent;
}
.tab-stacked-left .tab-content {
  border-left-color: transparent;
  border-radius: 0 0px 0px 0;
}
.tab-stacked-right .nav-tabs {
  width: 1%;
  border: 0;
}
.tab-stacked-right .nav-tabs > li a {
  border-left-color: transparent;
  border-radius: 0 0px 0px 0;
}
.tab-stacked-right .nav-tabs > .active > a:hover,
.tab-stacked-right .nav-tabs > .active > a:focus {
  border-left-color: transparent;
}
.tab-stacked-right.tab-base .nav-tabs > li:not(.active) a:hover {
  border-color: #fecb6e;
  border-left-color: transparent;
}
.tab-stacked-right .tab-content {
  border-right-color: transparent;
  border-radius: 0px 0 0 0px;
}
/* BACKGROUND */
/*======================================*/
.bg-trans {
  background-color: transparent;
}
.bg-light {
  background-color: #ffffff;
}
.bg-light,
.bg-light a {
  color: #212121;
}
.bg-gray-light {
  background-color: #ffffff;
}
.bg-gray-light,
.bg-gray-light a {
  color: #212121;
}
.bg-gray {
  background-color: #f3f3f3;
}
.bg-gray,
.bg-gray a {
  color: #212121;
}
.bg-gray-dark {
  background-color: #dadada;
}
.bg-gray-dark,
.bg-gray-dark a {
  color: #212121;
}
.bg-trans-light {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-trans-light,
.bg-trans-light a {
  color: inherit;
}
.bg-trans-dark {
  background-color: rgba(0, 0, 0, 0.05);
}
.bg-trans-dark,
.bg-trans-dark a {
  color: inherit;
}
.bg-gray-light-inherit {
  background-color: #ffffff;
}
.bg-primary {
  background-color: #5fa2dd;
}
.bg-primary,
.bg-primary a {
  color: #ffffff;
}
.bg-info {
  background-color: #4ebcda;
}
.bg-info,
.bg-info a {
  color: #ffffff;
}
.bg-success {
  background-color: #89c746;
}
.bg-success,
.bg-success a {
  color: #ffffff;
}
.bg-warning {
  background-color: #ffb400;
}
.bg-warning,
.bg-warning a {
  color: #ffffff;
}
.bg-danger {
  background-color: #e80000;
}
.bg-danger,
.bg-danger a {
  color: #ffffff;
}
/* TEXT */
/*======================================*/
.text-light,
a.text-light:hover,
a.text-light:focus,
a.text-light:focus {
  color: #ffffff;
}
.text-muted,
a.text-muted:hover,
a.text-muted:focus,
a.text-muted:focus {
  color: #bcb8b0;
}
.text-primary,
a.text-primary:hover,
a.text-primary:focus,
a.text-primary:focus {
  color: #5fa2dd;
}
.text-info,
a.text-info:hover,
a.text-info:focus,
a.text-info:focus {
  color: #4ebcda;
}
.text-success,
a.text-success:hover,
a.text-success:focus,
a.text-success:focus {
  color: #89c746;
}
.text-warning,
a.text-warning:hover,
a.text-warning:focus,
a.text-warning:focus {
  color: #ffb400;
}
.text-danger,
a.text-danger:hover,
a.text-danger:focus,
a.text-danger:focus {
  color: #e80000;
}
/* BUTTONS */
/*======================================*/
.btn {
  cursor: pointer;
  background-color: transparent;
  color: inherit;
  padding: 6px 12px;
  border-radius: 0px;
  border: 1px solid 0px;
  font-size: 13px;
  line-height: 1.42857;
  vertical-align: middle;
  white-space: normal;
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
}
.btn:not(.disabled):not(:disabled):active,
.btn:not(.disabled):not(:disabled).active {
  -webkit-box-shadow: none;
          box-shadow: none;
  background: #fff;
  color: #fecb6e;
}
/* Button Sizes */
/*---------------------------------*/
.btn-lg,
.btn-icon.btn-lg {
  font-size: 17px;
  line-height: 1.33;
}
.btn-sm,
.btn-icon.btn-sm {
  font-size: 11px;
  line-height: 1.5;
}
.btn-xs,
.btn-icon.btn-xs {
  font-size: 11px;
  line-height: 1.5;
}
/* Button Icon */
/*---------------------------------*/
.btn-icon {
  padding-left: 9px;
  padding-right: 9px;
}
.btn-icon:before {
  display: inline-block;
  min-width: 1.05em;
}
.btn-icon.btn-xs {
  border-radius: 0px;
}
.btn-icon.btn-sm {
  border-radius: 0px;
}
.btn-icon.btn-lg {
  border-radius: 0px;
}
/* Button File */
/*---------------------------------*/
.btn-file {
  position: relative;
  overflow: hidden;
}
.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
/* Button Link */
/*---------------------------------*/
.btn-link {
  border-color: transparent;
}
.btn.btn-link:focus,
.btn.btn-link:active {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn-link.disabled:hover,
.btn-link.disabled:focus {
  text-decoration: none;
}
.btn-default,
.btn-default:focus,
.btn-hover-default:hover,
.btn-hover-default:active,
.btn-hover-default.active,
.btn.btn-active-default:active,
.btn.btn-active-default.active,
.dropdown.open > .btn.btn-active-default,
.btn-group.open .dropdown-toggle.btn.btn-active-default {
  background-color: #fafafa;
  border-color: #e1e1e1;
  color: #212121;
}
.btn-default:hover,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-color: #e9e9e9;
  border-color: #e1e1e1;
  color: #212121;
}
.btn-primary,
.btn-primary:focus,
.btn-hover-primary:hover,
.btn-hover-primary:active,
.btn-hover-primary.active,
.btn.btn-active-primary:active,
.btn.btn-active-primary.active,
.dropdown.open > .btn.btn-active-primary,
.btn-group.open .dropdown-toggle.btn.btn-active-primary {
  background-color: #579ddb;
  border-color: #5fa2dd;
  color: #ffffff;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  background-color: #3b8dd5;
  border-color: #4291d7;
  color: #ffffff;
}
.btn-info,
.btn-info:focus,
.btn-hover-info:hover,
.btn-hover-info:active,
.btn-hover-info.active,
.btn.btn-active-info:active,
.btn.btn-active-info.active,
.dropdown.open > .btn.btn-active-info,
.btn-group.open .dropdown-toggle.btn.btn-active-info {
  background-color: #46b9d8;
  border-color: #4ebcda;
  color: #ffffff;
}
.btn-info:hover,
.btn-info:active,
.btn-info.active,
.open > .dropdown-toggle.btn-info {
  background-color: #2caed1;
  border-color: #30b1d4;
  color: #ffffff;
}
.btn-success,
.btn-success:focus,
.btn-hover-success:hover,
.btn-hover-success:active,
.btn-hover-success.active,
.btn.btn-active-success:active,
.btn.btn-active-success.active,
.dropdown.open > .btn.btn-active-success,
.btn-group.open .dropdown-toggle.btn.btn-active-success {
  background-color: #84c53e;
  border-color: #89c746;
  color: #ffffff;
}
.btn-success:hover,
.btn-success:active,
.btn-success.active,
.open > .dropdown-toggle.btn-success {
  background-color: #73ad34;
  border-color: #77b336;
  color: #ffffff;
}
.btn-warning,
.btn-warning:focus,
.btn-hover-warning:hover,
.btn-hover-warning:active,
.btn-hover-warning.active,
.btn.btn-active-warning:active,
.btn.btn-active-warning.active,
.dropdown.open > .btn.btn-active-warning,
.btn-group.open .dropdown-toggle.btn.btn-active-warning {
  background-color: #f5ad00;
  border-color: #ffb400;
  color: #ffffff;
}
.btn-warning:hover,
.btn-warning:active,
.btn-warning.active,
.open > .dropdown-toggle.btn-warning {
  background-color: #d49500;
  border-color: #db9b00;
  color: #ffffff;
}
.btn-danger,
.btn-danger:focus,
.btn-hover-danger:hover,
.btn-hover-danger:active,
.btn-hover-danger.active,
.btn.btn-active-danger:active,
.btn.btn-active-danger.active,
.dropdown.open > .btn.btn-active-danger,
.btn-group.open .dropdown-toggle.btn.btn-active-danger {
  background-color: #de0000;
  border-color: #e80000;
  color: #ffffff;
}
.btn-danger:hover,
.btn-danger:active,
.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  background-color: #bd0000;
  border-color: #c40000;
  color: #ffffff;
}
.btn-group-vertical .btn:not(.btn-default),
.btn-group .btn:not(.btn-default) {
  border-color: rgba(0, 0, 0, 0.09);
}
.btn-lg,
.btn-group-lg > .btn,
.btn-icon.btn-lg {
  padding: 10px 16px;
}
.btn-sm,
.btn-group-sm > .btn,
.btn-icon.btn-sm {
  padding: 5px 10px;
}
.btn-xs,
.btn-group-xs > .btn,
.btn-icon.btn-xs {
  padding: 1px 5px;
}
.btn-lg,
.btn-group-lg > .btn,
.btn-icon.btn-lg {
  border-radius: 0px;
}
.btn-lg,
.btn-group-sm > .btn,
.btn-lg,
.btn-group-xs > .btn,
.btn-icon.btn-lg,
.btn-icon.btn-lg {
  border-radius: 0px;
}
.btn-group-vertical > .btn:first-child:not(:last-child) {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.btn-group-vertical > .btn:last-child:not(:first-child) {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
/* Button Circle */
/*---------------------------------*/
.btn-circle {
  padding: 7px;
  border-radius: 50%;
}
.btn-lg.btn-circle {
  padding: 17px;
}
.btn-sm.btn-circle {
  padding: 7px;
}
.btn-xs.btn-circle {
  padding: 4px;
}
.btn-icon.btn-circle:before {
  display: block;
  width: 1.4em;
  height: 1.4em;
  line-height: 1.4;
}
/* Button Rounded */
/*---------------------------------*/
.btn-rounded {
  border-radius: 17px;
  overflow: hidden;
}
.btn-lg.btn-rounded {
  border-radius: 30px;
}
.btn-sm.btn-rounded {
  border-radius: 15px;
}
.btn-xs.btn-rounded {
  border-radius: 10px;
}
.btn-group.btn-rounded {
  overflow: hidden;
}
/* Button Labeled */
/*---------------------------------*/
.btn-labeled,
.btn-labeled.fa,
.btn-labeled.wicon,
.btn-labeled.glyphicon {
  overflow: hidden;
}
.btn-labeled:not(.btn-block):not(.form-icon) {
  font-family: inherit;
  font-size: 13px;
  line-height: 1.42857;
  padding-bottom: 0;
  padding-top: 0;
}
.btn-block.btn-labeled:not(.form-icon) {
  font-family: inherit;
  font-size: 13px;
  line-height: 1.42857;
}
.btn-block.btn-labeled:not(.form-icon):before {
  float: left;
  margin-top: -7px;
  margin-bottom: -7px;
}
.btn-labeled .btn-label {
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin-left: -12px;
  margin-right: 6px;
  padding: 6px 12px;
  line-height: 1.42857;
}
.btn-labeled:before {
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-block;
  margin-left: -12px;
  margin-right: 6px;
  padding: 6px 12px;
}
.btn-labeled.fa:before,
.btn-labeled .fa:before {
  font-family: fontAwesome;
}
.btn-labeled.glyphicon:before,
.btn-labeled .glyphicon:before {
  font-family: Glyphicons Halflings;
}
.btn-labeled.wicon:before,
.btn-labeled .wicon:before {
  font-family: winspa;
}
.btn-default.btn-labeled:before,
.btn-default .btn-label {
  background-color: rgba(0, 0, 0, 0.05);
  color: inherit;
}
.btn-lg.btn-labeled {
  font-size: 18px;
  line-height: 1.33;
}
.btn-sm.btn-labeled {
  font-size: 12px;
  line-height: 1.5;
}
.btn-xs.btn-labeled {
  font-size: 12px;
  line-height: 1.5;
}
.btn-lg.btn-labeled:before,
.btn-lg .btn-label {
  margin-left: -16px;
  margin-right: 10px;
  padding: 10px 16px;
}
.btn-lg.btn-block.btn-labeled:not(.form-icon):before {
  margin-top: -10px;
  margin-bottom: -10px;
}
.btn-sm.btn-labeled:before,
.btn-sm .btn-label {
  margin-left: -10px;
  margin-right: 5px;
  padding: 5px 10px;
}
.btn-sm.btn-block.btn-labeled:not(.form-icon):before {
  margin-top: -5px;
  margin-bottom: -5px;
}
.btn-xs.btn-labeled:before,
.btn-xs .btn-label {
  margin-left: -5px;
  margin-right: 1px;
  padding: 1px 5px;
}
.btn-xs.btn-block.btn-labeled:not(.form-icon):before {
  margin-top: -1px;
  margin-bottom: -1px;
}
.btn-labeled.icon-2x:before,
.btn-labeled .btn-label.icon-2x:before {
  vertical-align: -0.15em;
}
.btn-labeled.icon-3x:before,
.btn-labeled .btn-label.icon-3x:before {
  vertical-align: -0.18em;
}
.btn-labeled.icon-4x:before,
.btn-labeled .btn-label.icon-4x:before {
  vertical-align: -0.2em;
}
.btn-labeled.icon-5x:before,
.btn-labeled .btn-label.icon-5x:before {
  vertical-align: -0.25em;
}
/* PANELS */
/*======================================*/
.panel {
  -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
          box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  border: 0;
  margin-bottom: 30px;
}
.panel hr {
  border-color: rgba(0, 0, 0, 0.1);
}
.panel .panel-bg-cover {
  max-height: 180px;
  overflow: hidden;
}
.panel .panel-bg-cover img {
  min-width: 100%;
  min-height: 100%;
  background-size: cover;
}
.panel.remove {
  opacity: 0;
  -webkit-transition: opacity, 0.5s;
  transition: opacity, 0.5s;
}
.panel .alert {
  border-radius: 0;
}
.panel.panel-bg-img {
  position: relative;
}
.panel .panel-bg-wrap {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.panel .panel-bg-wrap > img {
  position: absolute;
  top: 0;
  left: 0;
}
.panel .panel-bg-wrap + .panel-body {
  position: relative;
}
.panel-media {
  -webkit-box-shadow: 0 -50px 20px -10px rgba(0, 0, 0, 0.2);
          box-shadow: 0 -50px 20px -10px rgba(0, 0, 0, 0.2);
  padding: 10px 15px 15px 140px;
  position: relative;
}
.panel-media-img {
  position: absolute;
  width: 96px;
  height: 96px;
  left: 20px;
  top: -48px;
}
.panel-media-heading {
  color: #fff;
  position: absolute;
  top: -2.7em;
}
.panel .panel-heading,
.panel > :first-child {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.panel .panel-footer,
.panel > :last-child {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.panel-body-full {
  margin-left: -20px;
  margin-right: -20px;
}
.panel-body {
  padding: 25px 20px;
}
.panel-body.panel-ordini {
  padding: 0 20px 25px;
}
.panel-body .row,
.panel-body .form-horizontal .form-group {
  margin-left: -5px;
  margin-right: -5px;
}
.panel-trans {
  border-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.panel-heading {
  position: relative;
  height: 50px;
  padding: 0;
}
.panel-title {
  padding: 5px 20px;
  font-size: 1.5em;
  font-weight: 300;
  line-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.panel-default.panel-colorful {
  background-color: #f0f0f0;
  color: #212121;
}
.panel-default .panel-heading {
  background-color: #f0f0f0;
  border-color: #fdfdfd;
}
.panel-footer {
  background-color: #fffdf9;
  color: #807a6d;
  border-color: #fff5df;
  position: relative;
  padding: 10px 20px;
}
/* Mixin  */
/*---------------------------------*/
/* Colors */
/*---------------------------------*/
.panel-primary .panel-heading,
.panel-primary .panel-footer,
.panel-primary.panel-colorful {
  background-color: #5fa2dd;
  border-color: #5fa2dd;
  color: #ffffff;
}
.panel-info .panel-heading,
.panel-info .panel-footer,
.panel-info.panel-colorful {
  background-color: #4ebcda;
  border-color: #4ebcda;
  color: #ffffff;
}
.panel-success .panel-heading,
.panel-success .panel-footer,
.panel-success.panel-colorful {
  background-color: #89c746;
  border-color: #89c746;
  color: #ffffff;
}
.panel-warning .panel-heading,
.panel-warning .panel-footer,
.panel-warning.panel-colorful {
  background-color: #ffb400;
  border-color: #ffb400;
  color: #ffffff;
}
.panel-danger .panel-heading,
.panel-danger .panel-footer,
.panel-danger.panel-colorful {
  background-color: #e80000;
  border-color: #e80000;
  color: #ffffff;
}
.panel > .panel-heading:after,
.panel.panel-colorful > .panel-heading:after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  left: 20px;
  right: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.panel > .panel-heading.no-after:after,
.panel.panel-colorful > .panel-heading.no-after:after {
  border-bottom: none;
}
.panel-colorful > .panel-heading {
  border: 0;
}
.panel-default > .panel-heading:after,
.panel-primary > .panel-heading:after,
.panel-info > .panel-heading:after,
.panel-success > .panel-heading:after,
.panel-warning > .panel-heading:after,
.panel-danger > .panel-heading:after,
.panel-purple > .panel-heading:after,
.panel-pink > .panel-heading:after,
.panel-dark > .panel-heading:after {
  display: none;
}
/* Bordered */
/*---------------------------------*/
.panel-bordered-default,
.panel-default.panel-bordered {
  border: 1px solid #cdcdcd;
}
.panel-bordered-primary,
.panel-primary.panel-bordered {
  border: 1px solid #5fa2dd;
}
.panel-bordered-info,
.panel-info.panel-bordered {
  border: 1px solid #4ebcda;
}
.panel-bordered-success,
.panel-success.panel-bordered {
  border: 1px solid #89c746;
}
.panel-bordered-warning,
.panel-warning.panel-bordered {
  border: 1px solid #ffb400;
}
.panel-bordered-danger,
.panel-danger.panel-bordered {
  border: 1px solid #e80000;
}
/* Panel Group */
/*---------------------------------*/
.panel-group .panel {
  border-radius: 0;
  margin-bottom: 20px;
}
.panel-group > div {
  padding-left: 0;
  padding-right: 0;
}
.panel-group > div:first-child > .panel {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.panel-group > div:last-child > .panel {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.panel-group > div + div > .panel {
  margin-left: -1px;
}
/* Panel Control */
/*---------------------------------*/
.panel-control {
  height: 100%;
  position: relative;
  float: right;
  padding: 0 15px;
}
.panel-control:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  left: -1em;
  position: relative;
}
.panel-control > i,
.panel-control > .badge,
.panel-control > label {
  vertical-align: middle;
}
/* Panel with tabs */
/*---------------------------------*/
.panel-control .nav-tabs {
  display: inline-block;
  height: 40px;
  margin-top: 10px;
  vertical-align: bottom;
  border: 0;
}
.panel-control .nav-tabs > li {
  margin-top: 1px;
  margin-right: 5px;
  height: 100%;
}
.panel-control .nav-tabs > li > a {
  border-radius: 0;
  margin-right: 0;
  height: 100%;
  line-height: 40px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  padding: 0 15px;
}
.panel-control .nav-tabs > li:not(.active) {
  background-color: transparent;
  opacity: .6;
}
.panel-control .nav-tabs > li:not(.active) a {
  color: inherit;
  border-bottom: 0 !important;
}
.panel-control .nav-tabs > li:not(.active) a:hover {
  background-color: rgba(0, 0, 0, 0.15);
  border-color: transparent;
}
.panel-control .nav-tabs > .active > a {
  z-index: 1;
}
/* Panel with progress bar */
/*---------------------------------*/
.panel-control .progress {
  min-width: 150px;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
}
/* Panel with switch */
/*---------------------------------*/
.panel-control .switchery {
  margin-left: 15px;
  vertical-align: middle;
}
/* Panel with pager */
/*---------------------------------*/
.panel-control .pager {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  border-radius: 0;
}
/* Panel with pagination */
/*---------------------------------*/
.panel-control .pagination {
  margin: 0;
  border-radius: 0;
  vertical-align: middle;
}
.panel-control .pagination > li > a,
.panel-control .pagination > li > span {
  padding: 0 10px;
  border: 0px 0px 0 0;
  border-color: rgba(0, 0, 0, 0.09);
  -webkit-box-shadow: none;
          box-shadow: none;
  height: 100%;
  line-height: 30px;
}
.panel-control .pagination > li:not(.active):not(.disabled) > a:hover {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: rgba(0, 0, 0, 0.09);
}
.panel-control .pagination > .disabled > a,
.panel-control .pagination > .disabled > a:hover,
.panel-control .pagination > .disabled > a:active {
  border-color: rgba(0, 0, 0, 0.09);
}
.panel-control .pagination > li:not(.active) > a,
.pagination > li > a {
  background-color: transparent;
  color: inherit;
}
.panel-control .pagination > li > a:hover,
.pagination > li > a:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
}
/* Panel with pagination */
/*---------------------------------*/
.panel-control .btn,
.panel-control .dropdown-toggle.btn {
  border: 0;
}
.panel-control .open > .btn,
.panel-control .btn.active,
.panel-control .btn:active {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.panel-control .btn-default {
  background-color: transparent;
  color: inherit;
}
.panel-control > .btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.panel-control > .btn:last-child,
.panel-control > .btn-group:last-child > .btn:first-child {
  border-bottom-right-radius: 0;
}
/* POSITION */
/*======================================*/
.pos-rel {
  position: relative;
}
.pos-abs {
  position: absolute;
}
.pos-fix {
  position: fixed;
}
/* BOX TYPE */
/*======================================*/
.box-block {
  display: block;
}
.box-inline {
  display: inline-block;
}
.box-table {
  display: table;
  height: 100%;
}
.table-cell {
  display: table-cell;
  vertical-align: middle;
}
/* SIZES */
/*======================================*/
.full-height {
  height: 100%;
}
.height-lg {
  height: 75%;
}
.height-md {
  height: 50%;
}
.height-sm {
  height: 25%;
}
.full-width {
  width: 100%;
}
.width-lg {
  width: 75%;
}
.width-md {
  width: 50%;
}
.width-sm {
  width: 25%;
}
/* ALIGNMENT */
/*======================================*/
.align-top {
  vertical-align: top;
}
.align-mid {
  vertical-align: middle;
}
.align-btm {
  vertical-align: bottom;
}
/* BORDER */
/*======================================*/
.bord-no {
  border: 0 !important;
}
.bord-all {
  border: 1px solid #e0d7c0 !important;
}
.bord-top {
  border-top: 1px solid #e0d7c0 !important;
}
.bord-btm {
  border-bottom: 1px solid #e0d7c0 !important;
}
.bord-lft {
  border-left: 1px solid #e0d7c0 !important;
}
.bord-rgt {
  border-right: 1px solid #e0d7c0 !important;
}
.bord-ver {
  border-top: 1px solid #e0d7c0 !important;
  border-bottom: 1px solid #e0d7c0 !important;
}
.bord-hor {
  border-right: 1px solid #e0d7c0 !important;
  border-left: 1px solid #e0d7c0 !important;
}
.bord-brand {
  border-color: #fecb6e !important;
}
.bord-contrast {
  border-color: #072d1d !important;
}
/* SHADOW */
/*======================================*/
.text-shd {
  text-shadow: none;
}
.box-shd {
  -webkit-box-shadow: 0px 1px 4px rgba(7, 45, 29, 0.1);
          box-shadow: 0px 1px 4px rgba(7, 45, 29, 0.1);
}
.text-shd-no {
  text-shadow: none !important;
}
.box-shd-no {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
/* TEXT */
/*======================================*/
.font-base {
  font-family: 'Open Sans', sans-serif;
}
.font-title {
  font-family: 'Kaushan Script', cursive;
}
.text-thin {
  font-weight: 100;
}
.text-lite {
  font-weight: 300;
}
.text-regular {
  font-weight: 400;
}
.text-semibold {
  font-weight: 600;
}
.text-bold {
  font-weight: 700;
}
.text-4x {
  font-size: 34px;
}
.text-3x {
  font-size: 28px;
  line-height: 1.4em;
}
.text-2x {
  font-size: 22px;
}
.text-lg {
  font-size: 18px;
}
@media (max-width: 1199px) {
  .text-lg {
    font-size: 16px;
  }
}
.text-sm {
  font-size: 12px;
}
.text-xs {
  font-size: 10px;
}
.text-top {
  vertical-align: text-top;
}
.text-btm {
  vertical-align: text-bottom;
}
.text-overflow {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-underline {
  text-decoration: underline;
}
.no-decoration {
  text-decoration: none;
}
.unselectable {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.unselectable ::-moz-selection {
  background-color: transparent;
  color: #fecb6e;
}
.unselectable ::selection {
  background-color: transparent;
  color: #fecb6e;
}
.unselectable ::-moz-selection {
  background-color: transparent;
  color: #fecb6e;
}
.text-darker {
  color: #807a6d;
}
.text-brand {
  color: #fecb6e;
}
.text-contrast {
  color: #072d1d;
}
.text-body {
  color: #807a6d;
}
a.text-body,
a .text-body {
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
a.text-body:hover,
a .text-body:hover {
  color: #fecb6e;
}
@media (min-width: 1200px) {
  .text-lg-right {
    text-align: right;
  }
  .text-lg-center {
    text-align: center;
  }
  .text-lg-left {
    text-align: left;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .text-md-right {
    text-align: right;
  }
  .text-md-center {
    text-align: center;
  }
  .text-md-left {
    text-align: left;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .text-sm-right {
    text-align: right;
  }
  .text-sm-center {
    text-align: center;
  }
  .text-sm-left {
    text-align: left;
  }
}
@media (max-width: 760px) {
  .text-xs-right {
    text-align: right;
  }
  .text-xs-center {
    text-align: center;
  }
  .text-xs-left {
    text-align: left;
  }
}
/* TEXTAREA */
/*======================================*/
.no-resize {
  resize: none;
}
.resize-hor {
  resize: horizontal;
}
.resize-ver {
  resize: vertical;
}
/* IMAGES */
/*======================================*/
.img-mar {
  margin: 5px;
}
.img-border {
  -webkit-box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
          box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.1);
}
.img-border-light {
  -webkit-box-shadow: 0 0 0 4px #fff;
          box-shadow: 0 0 0 4px #fff;
}
.img-xs {
  width: 32px;
  height: 32px;
}
.img-sm {
  width: 46px;
  height: 46px;
}
.img-md {
  width: 64px;
  height: 64px;
}
.img-lg {
  width: 96px;
  height: 96px;
}
.img-xl {
  width: 120px;
  height: 120px;
}
.img-icon {
  width: 36px;
  height: 36px;
  display: block;
}
.img-holder img {
  max-width: 100%;
  border-radius: 0px;
}
.hover:hover {
  -webkit-transition: all 0.25s;
  transition: all 0.25s;
  opacity: .5;
}
/* CONTENT MARGIN */
/*======================================*/
.mar-auto {
  margin-left: auto;
  margin-right: auto;
}
.mar-no {
  margin: 0 !important;
}
.mar-all {
  margin: 15px !important;
}
.mar-top {
  margin-top: 15px !important;
}
.mar-btm {
  margin-bottom: 15px !important;
}
.mar-lft {
  margin-left: 15px !important;
}
.mar-rgt {
  margin-right: 15px !important;
}
.mar-hor {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.mar-ver {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.mar-min-all {
  margin: 5px;
}
.mar-min-top {
  margin-top: 5px;
}
.mar-min-btm {
  margin-bottom: 5px;
}
.mar-min-lft {
  margin-left: 5px;
}
.mar-min-rgt {
  margin-right: 5px;
}
.mar-min-hor {
  margin-left: 5px;
  margin-right: 5px;
}
.mar-min-ver {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mar-huge-top {
  margin-top: 80px;
}
.mar-huge-btm {
  margin-bottom: 80px;
}
.mar-huge-ver {
  margin-top: 80px;
  margin-bottom: 80px;
}
/* CONTENT PADDING */
/*======================================*/
.pad-no {
  padding: 0 !important;
}
.pad-all {
  padding: 15px !important;
}
.pad-top {
  padding-top: 15px !important;
}
.pad-btm {
  padding-bottom: 15px !important;
}
.pad-lft {
  padding-left: 15px !important;
}
.pad-rgt {
  padding-right: 15px !important;
}
.pad-hor {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.pad-ver {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.pad-min-all {
  padding: 5px;
}
.pad-min-top {
  padding-top: 5px;
}
.pad-min-btm {
  padding-bottom: 5px;
}
.pad-min-lft {
  padding-left: 5px;
}
.pad-min-rgt {
  padding-right: 5px;
}
.pad-min-hor {
  padding-left: 5px;
  padding-right: 5px;
}
.pad-min-ver {
  padding-top: 5px;
  padding-bottom: 5px;
}
.pad-huge-top {
  padding-top: 80px;
}
.pad-huge-btm {
  padding-bottom: 80px;
}
.pad-huge-ver {
  padding-top: 80px;
  padding-bottom: 80px;
}
.pad-huge-hor {
  padding-left: 80px;
  padding-right: 80px;
}
/* BACKGROUND IMAGES */
/*======================================*/
.bg-cover,
footer .sponsor,
#cb-slider .carousel-inner .item,
.para-sec a.big-box .para,
.circle .room {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-contain {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
/* DISABLED BUTTON */
/*======================================*/
a.disabled-link,
a.disabled-link:visited,
a.disabled-link:active,
a.disabled-link:hover {
  color: #aaa !important;
  cursor: default;
}
/* BUTTON WIDTH */
/*======================================*/
.btn-wid-4x {
  min-width: 10em;
}
.btn-wid-3x {
  min-width: 9em;
}
.btn-wid-2x {
  min-width: 8em;
}
.btn-wid-lg {
  min-width: 7em;
}
.btn-wid-sm {
  min-width: 6em;
}
.btn-wid-xs {
  min-width: 4em;
}
@media (min-width: 991px) {
  .eq-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}
.navbar {
  z-index: 2000;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.navbar .navbar-header {
  display: inline-block;
  float: left;
}
.navbar .navbar-header .navbar-brand {
  height: auto;
  padding: 25px 10px 10px 10px;
}
.navbar .navbar-header .navbar-brand:hover {
  color: #fdb022;
}
.navbar .navbar-nav {
  padding: 20px 25px;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.navbar .navbar-nav li {
  display: inline-block;
  margin-left: 10px;
  text-align: center;
}
.navbar .navbar-nav li a {
  font-size: 15px;
  padding: 8px 0 0;
  color: #fecb6e;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #fecb6e;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.navbar .navbar-nav li a:hover {
  color: #072d1d;
  background-color: #fecb6e;
}
.navbar .navbar-nav li a:focus {
  color: #fecb6e;
  background-color: transparent;
}
.navbar .navbar-nav li a.flag-box {
  overflow: hidden;
  padding: 0;
}
.navbar .navbar-nav li a.flag-box .flag-icon {
  font-size: 42px;
  margin: -1.5px 0 0 -2px;
}
.navbar .navbar-nav li a.flag-box:hover,
.navbar .navbar-nav li a.flag-box:focus {
  border-color: #fecb6e;
}
.navbar .navbar-nav li .flag-dropdown {
  border: none;
  background-color: transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  min-width: 0;
}
.navbar .navbar-nav li .flag-dropdown li {
  display: block;
  margin: 10px 0 0;
}
.navbar.navdown .navbar-header .navbar-brand {
  padding: 10px 5px 0;
}
.navbar.navdown .navbar-header .navbar-brand span {
  padding: 0 7px;
}
.navbar.navdown .navbar-nav {
  padding: 15px 10px 0;
}
@media (max-width: 767px) {
  .navbar.navdown .navbar-nav {
    padding: 0 10px;
  }
}
.navbar.navdown .navbar-nav li {
  margin-left: 5px;
}
.navbar.navdown .navbar-nav li a.top-down {
  width: 30px;
  height: 30px;
  padding: 4px 0 0;
  font-size: 12px;
}
.navbar.navdown .navbar-nav li a.flag-box {
  padding: 0;
}
.navbar.navdown .navbar-nav li a.flag-box .flag-icon {
  font-size: 32px;
  margin-left: -2px;
}
.navbar.navdown .navbar-nav li .flag-dropdown li {
  margin: 5px 0 0;
}
.page .navbar {
  background-color: #fff3da;
}
/* DON'T COLLAPSE */
.navbar-collapse.collapse {
  display: block!important;
  border: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.navbar-nav > li,
.navbar-nav {
  float: left !important;
}
.navbar-nav.navbar-right:last-child {
  margin-right: -15px !important;
}
.navbar-right {
  float: right!important;
}
footer {
  padding: 30px 0;
  font-size: 11px;
  background-color: #072d1d;
  color: #fff3da;
}
footer p {
  margin-bottom: 0;
  line-height: 20px;
}
footer a {
  color: #fff3da;
}
footer a:hover {
  color: #fecb6e;
}
footer a.text-brand:hover {
  color: #eb9802;
}
footer .table-cont {
  height: 100px;
}
@media (max-width: 767px) {
  footer .table-cont {
    height: auto;
  }
}
footer .sponsor {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  margin: 10px 5px 0;
}
footer .sponsor1 {
  background-image: url('../img/sponsor1.jpg');
}
footer .sponsor2 {
  background-image: url('../img/sponsor2.jpg');
}
footer .sponsor3 {
  background-image: url('../img/sponsor3.png');
}
footer .sponsor4 {
  background-image: url('../img/sponsor4.jpg');
}
footer .sponsor5 {
  background-image: url('../img/sponsor5.jpg');
}
footer .sponsor:hover {
  -webkit-box-shadow: 0px 0px 25px rgba(254, 203, 110, 0.5);
          box-shadow: 0px 0px 25px rgba(254, 203, 110, 0.5);
}
/* Button Efx */
/*---------------------------------*/
.btn-darker {
  background-color: #807a6d;
  border: 1px solid #807a6d;
  color: #fff !important;
}
.btn-darker:hover,
.btn-darker:focus,
.btn-darker:active {
  background-color: #646056;
  border: 1px solid #646056;
}
.btn-brand {
  background-color: #fecb6e;
  border: 1px solid #fecb6e;
  color: #fff !important;
}
.btn-brand:hover,
.btn-brand:focus,
.btn-brand:active {
  background-color: #feb93b;
  border: 1px solid #feb93b;
}
.btn-contrast {
  background-color: #072d1d;
  border: 1px solid #072d1d;
  color: #fff !important;
}
.btn-contrast:hover,
.btn-contrast:focus,
.btn-contrast:active {
  background-color: #000101;
  border: 1px solid #000101;
}
/* Buttons */
/*---------------------------------*/
.btn {
  padding: 12px 34px;
}
.btn-yel {
  background-color: transparent;
  border: 1px solid #fff3da;
  color: #fff !important;
  color: #fff3da !important;
  text-transform: uppercase;
}
.btn-yel:hover,
.btn-yel:focus,
.btn-yel:active {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #ffe2a7;
}
.btn-yel:hover {
  background-color: #fff3da;
  color: #072d1d !important;
}
.btn-cb {
  background-color: transparent;
  border: 1px solid #e0d7c0;
  color: #fff !important;
  color: #807a6d !important;
  text-transform: uppercase;
}
.btn-cb:hover,
.btn-cb:focus,
.btn-cb:active {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #cfc19e;
}
.btn-cb:hover {
  background-color: #807a6d;
  color: #fff3da !important;
  border: 1px solid #807a6d;
}
.modal-backdrop {
  background-color: #fecb6e;
}
.modal {
  z-index: 2100;
}
.modal.animated {
  -webkit-animation-duration: .5s;
          animation-duration: .5s;
}
.modal.fullscreen {
  padding-right: 0 !important;
}
.modal.fullscreen:before {
  display: none;
}
.modal.fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
}
.modal.fullscreen .modal-content {
  background-color: #fff3da;
  height: 100%;
  border-radius: 0;
  overflow: auto;
  padding-top: 130px;
}
.modal.fullscreen .modal-content .modal-header {
  width: 100%;
  border-bottom: none;
  z-index: 300;
  position: fixed;
  background-color: #fff3da;
  top: 0;
}
.modal.fullscreen .modal-content .modal-header button {
  border: none;
  background-color: transparent;
  font-size: 75px;
  color: rgba(128, 122, 109, 0.5);
  line-height: 70px;
  position: absolute;
  top: 25px;
  left: 20px;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.modal.fullscreen .modal-content .modal-header button:hover {
  color: #807a6d;
}
@media (max-width: 767px) {
  .modal.fullscreen .modal-content .modal-header button {
    font-size: 45px;
    line-height: 60px;
    top: 0;
    left: 15px;
  }
}
.modal.fullscreen .modal-content .modal-header .modal-title {
  margin: 0;
}
@media (max-width: 767px) {
  .modal.fullscreen .modal-content .modal-header .modal-title {
    font-size: 28px;
  }
}
.modal.fullscreen .modal-content .modal-body {
  padding-bottom: 80px;
}
.modal.fullscreen .modal-content .modal-body::-webkit-scrollbar {
  display: none;
}
.modal.fullscreen .modal-content .modal-body .text {
  font-size: 20px;
  line-height: 32px;
}
@media (max-width: 991px) {
  .modal.fullscreen .modal-content .modal-body .text {
    font-size: 15px;
    line-height: 23px;
    font-weight: 400;
  }
}
.modal.fullscreen .modal-content .modal-body .text-big {
  font-size: 28px;
  line-height: 1.4em;
  font-weight: 100;
}
@media (max-width: 991px) {
  .modal.fullscreen .modal-content .modal-body .text-big {
    font-size: 22px;
    line-height: 30px;
    font-weight: 300;
  }
}
@media (max-width: 767px) {
  .modal.fullscreen .modal-content .modal-body img {
    margin: 30px auto;
  }
}
#loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
#loader-wrapper #loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  border-radius: 100%;
  background-color: #072d1d;
  -webkit-animation: sk-scaleout 1s infinite ease-in-out;
          animation: sk-scaleout 1s infinite ease-in-out;
  z-index: 1001;
}
#loader-wrapper .loader-section {
  position: fixed;
  top: 0;
  width: 51%;
  height: 100%;
  background: #fecb6e;
  z-index: 1000;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
#loader-wrapper .loader-section.section-left {
  left: 0;
}
#loader-wrapper .loader-section.section-right {
  right: 0;
}
.loaded #loader-wrapper {
  visibility: hidden;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
  -webkit-transition: all 0.3s 1s ease-out;
  transition: all 0.3s 1s ease-out;
}
.loaded #loader-wrapper #loader {
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.loaded #loader-wrapper .loader-section.section-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.loaded #loader-wrapper .loader-section.section-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
  -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.no-js #loader-wrapper {
  display: none;
}
html,
body {
  -ms-overflow-style: scrollbar;
  min-height: 100%;
}
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased !important;
  quotes: '\201C' '\201D' '\2018' '\2019';
  width: 100%;
  background-color: #fff3da;
  color: #807a6d;
  text-align: center;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
h1,
.h1 {
  font-size: 36px;
}
@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 32px;
  }
}
h2,
.h2 {
  font-size: 30px;
}
@media (max-width: 991px) {
  h2,
  .h2 {
    font-size: 27px;
  }
}
h3,
.h3 {
  font-size: 24px;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  h3,
  .h3 {
    font-size: 20px;
  }
}
h4,
.h4 {
  font-size: 20px;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  h4,
  .h4 {
    font-size: 18px;
  }
}
h5,
.h5 {
  font-size: 15px;
}
@media (max-width: 991px) {
  h5,
  .h5 {
    font-size: 14px;
  }
}
h6,
.h6 {
  font-size: 12px;
}
@media (max-width: 991px) {
  h6,
  .h6 {
    font-size: 10px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
  color: #807a6d;
  line-height: 1.42857143;
}
a {
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
/* CUSTOM LAYOUT */
/*======================================*/
.animate-in {
  -webkit-animation: fadeIn 0.5s ease-in;
          animation: fadeIn 0.5s ease-in;
}
.animate-out {
  -webkit-transition: opacity .5s;
  transition: opacity .5s;
  opacity: 0;
}
@media (max-width: 767px) {
  .box-table {
    display: block;
  }
  .box-table img {
    margin: 30px 0;
  }
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.tooltip.top .tooltip-arrow {
  border-top-color: #fecb6e;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #fecb6e;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #fecb6e;
}
.tooltip .tooltip-inner {
  background: #fecb6e;
  color: #072d1d;
  padding: 5px 15px;
  border-radius: 1px;
}
.fluid-wrap {
  width: 100%;
}
.fluid-wrap .box-table {
  width: 100%;
}
/* CAROUSEL HOMEPAGE */
#cb-slider {
  width: 100%;
}
#cb-slider .carousel-inner {
  height: 100%;
}
#cb-slider .carousel-inner .item {
  width: 100%;
  height: 100%;
  background-attachment: fixed;
}
#cb-slider .carousel-inner .item-1 {
  background-image: url('../img/home_1.jpg');
}
#cb-slider .carousel-inner .item-2 {
  background-image: url('../img/home_2.jpg');
}
#cb-slider .carousel-inner .item-3 {
  background-image: url('../img/home_3.jpg');
}
#cb-slider .carousel-inner .item-4 {
  background-image: url('../img/home_4.jpg');
}
#cb-slider .carousel-inner .item-5 {
  background-image: url('../img/home_5.jpg');
}
#cb-slider .overlay {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(7, 45, 29, 0.6);
  z-index: 1000;
}
#cb-slider .overlay #top-title,
#cb-slider .overlay #top-subtitle {
  font-family: 'Kaushan Script', cursive;
  font-size: 120px;
  font-weight: normal;
  color: #fecb6e;
  text-shadow: 0 1.5px 0px rgba(7, 45, 29, 0.75);
}
@media (max-width: 767px) {
  #cb-slider .overlay #top-title,
  #cb-slider .overlay #top-subtitle {
    font-size: 50px;
  }
}
#cb-slider .overlay #top-subtitle {
  font-size: 22px;
}
#cb-slider .overlay #top-divider {
  margin: -5px auto 15px;
}
#cb-slider .overlay #top-divider .top-element {
  display: inline-block;
  background-color: #fecb6e;
  text-shadow: 0 1.5px 0px rgba(7, 45, 29, 0.75);
}
#cb-slider .overlay #top-divider .top-line {
  width: 220px;
  height: 1px;
  margin: 0 7px 4px;
}
@media (max-width: 767px) {
  #cb-slider .overlay #top-divider .top-line {
    width: 60px;
  }
}
#cb-slider .overlay #top-divider .top-circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
@media (max-width: 767px) {
  #cb-slider .overlay .box-table {
    display: table;
  }
}
/* DOWN BUTTON */
#top-nav {
  position: absolute;
  bottom: 40px;
  width: 100%;
  height: 40px;
  text-align: center;
}
#top-nav .top-down {
  border: 1px solid #fecb6e;
  display: inline-block;
  font-size: 15px;
  margin: 0 5px;
  color: #fecb6e;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 8px 0 0;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
#top-nav .top-down:hover {
  color: #072d1d;
  background: #fecb6e;
}
#top-nav .top-down:focus {
  color: #fecb6e;
  background: transparent;
}
/* CASALE */
.inner-section {
  padding: 140px 0 180px;
  margin: 0 auto;
  display: table;
}
@media (max-width: 991px) {
  .inner-section {
    padding: 100px 0 140px;
  }
}
.title {
  font-size: 70px;
  font-weight: normal;
  margin-bottom: 35px;
  color: #072d1d;
  font-family: 'Kaushan Script', cursive;
}
@media (max-width: 991px) {
  .title {
    font-size: 55px;
  }
}
@media (max-width: 767px) {
  .title {
    font-size: 40px;
  }
}
.text {
  font-size: 35px;
  line-height: 55px;
  font-weight: 300;
}
@media (max-width: 991px) {
  .text {
    font-size: 25px;
    line-height: 35px;
  }
}
@media (max-width: 767px) {
  .text {
    font-size: 20px;
    line-height: 30px;
  }
}
/* AMBIENTI */
.outer-section {
  height: auto;
  position: relative;
}
.para-sec {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.para-sec a.big-box {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.para-sec a.big-box:before,
.para-sec a.big-box:after {
  position: absolute;
  content: '';
  opacity: 0;
  z-index: 1300;
  -webkit-transition: opacity 0.55s, -webkit-transform 0.55s;
  transition: opacity 0.55s, -webkit-transform 0.55s;
  transition: opacity 0.55s, transform 0.55s;
  transition: opacity 0.55s, transform 0.55s, -webkit-transform 0.55s;
}
.para-sec a.big-box:before {
  top: 50px;
  right: 30px;
  bottom: 50px;
  left: 30px;
  border-top: 1px solid #fff3da;
  border-bottom: 1px solid #fff3da;
  -webkit-transform: scale(0, 1);
          transform: scale(0, 1);
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
}
.para-sec a.big-box:after {
  top: 30px;
  right: 50px;
  bottom: 30px;
  left: 50px;
  border-right: 1px solid #fff3da;
  border-left: 1px solid #fff3da;
  -webkit-transform: scale(1, 0);
          transform: scale(1, 0);
  -webkit-transform-origin: 100% 0;
          transform-origin: 100% 0;
}
.para-sec a.big-box .para {
  width: 100%;
  min-height: 300px;
  display: block;
  position: absolute;
  background-attachment: scroll;
  -webkit-transition: all, 0.55s;
  transition: all, 0.55s;
}
.para-sec a.big-box .para.amb-1 {
  background-image: url('../img/ambiente1.jpg');
}
.para-sec a.big-box .para.amb-2 {
  background-image: url('../img/ambiente2.jpg');
}
.para-sec a.big-box .para.amb-3 {
  background-image: url('../img/ambiente3.jpg');
}
.para-sec a.big-box .para.din-1 {
  background-image: url('../img/dintorni1.jpg');
}
.para-sec a.big-box .para.din-2 {
  background-image: url('../img/dintorni2.jpg');
}
.para-sec a.big-box .over-amb {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1250;
  padding: 3em;
  -webkit-transition: all, 0.35s;
  transition: all, 0.35s;
}
.para-sec a.big-box .over-amb-1 {
  background: rgba(117, 106, 86, 0.7);
}
.para-sec a.big-box .over-amb-2 {
  background: rgba(147, 114, 47, 0.7);
}
.para-sec a.big-box .over-amb-3 {
  background: rgba(154, 165, 101, 0.7);
}
.para-sec a.big-box .over-amb.over-din-1 {
  background: rgba(213, 181, 121, 0.7);
}
.para-sec a.big-box .over-amb.over-din-2 {
  background: rgba(230, 207, 159, 0.7);
}
.para-sec a.big-box .over-amb.over-din .title {
  margin-top: 27%;
  color: #072d1d;
  -webkit-transition: all, 0.35s;
  transition: all, 0.35s;
}
@media (max-width: 1199px) {
  .para-sec a.big-box .over-amb.over-din .title {
    margin-top: 20%;
  }
}
@media (max-width: 767px) {
  .para-sec a.big-box .over-amb.over-din .title {
    margin-top: 11%;
  }
}
@media (max-width: 430px) {
  .para-sec a.big-box .over-amb.over-din .title {
    margin-top: 25%;
  }
}
.para-sec a.big-box .over-amb.over-din p {
  color: #072d1d;
  font-size: 13px;
  padding: 0.5em 4em;
}
.para-sec a.big-box .over-amb .title {
  margin-top: 45%;
  display: block;
  font-size: 40px;
  color: #fff3da;
  -webkit-transition: all, 0.35s;
  transition: all, 0.35s;
}
@media (max-width: 1199px) {
  .para-sec a.big-box .over-amb .title {
    margin-top: 35%;
  }
}
@media (max-width: 991px) {
  .para-sec a.big-box .over-amb .title {
    margin-top: 27%;
    font-size: 28px;
  }
}
@media (max-width: 767px) {
  .para-sec a.big-box .over-amb .title {
    margin-top: 11%;
    font-size: 40px;
  }
}
@media (max-width: 430px) {
  .para-sec a.big-box .over-amb .title {
    margin-top: 25%;
  }
}
.para-sec a.big-box .over-amb p {
  color: #fff3da;
  font-size: 12px;
  padding: 0.5em 2em;
  text-transform: none;
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
          transform: translate3d(0, -10px, 0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
@media (max-width: 991px) {
  .para-sec a.big-box .over-amb p {
    font-size: 11px;
    padding: 0.5em 1.4em;
  }
}
@media (max-width: 767px) {
  .para-sec a.big-box .over-amb p {
    font-size: 14px;
    padding: 0.5em 2em;
  }
}
.para-sec:hover a:after,
.para-sec:hover a:before {
  opacity: 1;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.para-sec:hover a:after {
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.para-sec:hover a .para {
  background-position: left top;
}
.para-sec:hover a .over-amb .title {
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
}
.para-sec:hover a .over-amb p {
  opacity: 1;
  -webkit-transform: translate3d(0, -50px, 0);
          transform: translate3d(0, -50px, 0);
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
@media (max-width: 767px) {
  .para-sec:hover a:after,
  .para-sec:hover a:before {
    opacity: 0;
  }
  .para-sec:hover a .para {
    background-position: center center;
  }
  .para-sec:hover a .over-amb .title {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .para-sec:hover a .over-amb p {
    opacity: 0;
  }
}
/* LE STANZE */
.circle {
  width: 280px;
  height: 280px;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  display: block;
  margin: 95px auto 0;
}
@media (max-width: 991px) {
  .circle {
    width: 220px;
    height: 220px;
  }
}
@media (max-width: 767px) {
  .circle {
    margin: 35px auto 0;
  }
}
.circle .room {
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1301;
  background-attachment: scroll;
}
.circle .room-1 {
  background-image: url('../img/room_1.jpg');
}
.circle .room-2 {
  background-image: url('../img/room_2.jpg');
}
.circle .room-3 {
  background-image: url('../img/room_3.jpg');
}
.circle .room-amb-1 {
  background-image: url('../img/ambiente1.jpg');
}
.circle .room-amb-2 {
  background-image: url('../img/ambiente2.jpg');
}
.circle .room-amb-3 {
  background-image: url('../img/ambiente3.jpg');
}
.circle .room-din-1 {
  background-image: url('../img/dintorni1.jpg');
}
.circle .room-din-2 {
  background-image: url('../img/dintorni2.jpg');
}
.circle .over-room {
  background-color: rgba(255, 243, 218, 0.68);
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 3em;
  z-index: 1302;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.circle .over-room img {
  width: 40%;
  height: 40%;
  opacity: 0;
  -webkit-transform: translate3d(0, 20px, 0);
          transform: translate3d(0, 20px, 0);
  -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, -webkit-transform 0.35s;
  transition: opacity 0.35s, transform 0.35s;
  transition: opacity 0.35s, transform 0.35s, -webkit-transform 0.35s;
}
.circle .over-room .title {
  font-size: 30px;
  margin: 34% auto 0;
  display: block;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.circle:hover .over-room {
  background-color: rgba(255, 243, 218, 0.4);
}
.circle:hover .over-room .title {
  -webkit-transform: translate3d(0, -35px, 0);
          transform: translate3d(0, -35px, 0);
  text-shadow: 0 1px 0 #fff3da;
}
.circle:hover .over-room img {
  opacity: 1;
  -webkit-transform: translate3d(0, -20px, 0);
          transform: translate3d(0, -20px, 0);
  -webkit-transition-delay: 0.15s;
          transition-delay: 0.15s;
}
.inner-link {
  text-align: center;
  margin-bottom: 60px;
}
.inner-link .title {
  font-size: 50px;
}
.inner-link .circle {
  margin-top: 15px;
  width: 180px;
  height: 180px;
}
.inner-link .circle .title {
  font-size: 20px;
}
.inner-link .room-din-1 + .over-room .title {
  margin: 23% auto 0;
}
/* GALLERY */
.para-video video {
  max-width: 100%;
  height: auto;
  position: relative !important;
}
@media (max-width: 767px) {
  .para-video {
    display: none;
  }
}
.over-video {
  position: absolute;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  padding: 3em;
  z-index: 1302;
  background-image: url('../img/pattern.png');
  background-position: 0 0;
  background-repeat: repeat;
}
@media (max-width: 767px) {
  .over-video {
    background-color: #072d1d;
  }
}
.over-video .para-text {
  width: 100%;
  margin: 140px auto;
  text-align: center;
  font-size: 60px;
  color: #fff3da;
}
@media (max-width: 1199px) {
  .over-video .para-text {
    margin: 50px auto;
  }
}
@media (max-width: 767px) {
  .over-video .para-text {
    font-size: 30px;
  }
}
.img-preview {
  height: 230px;
  width: 100%;
  -webkit-transition: all, 0.25s;
  transition: all, 0.25s;
}
.img-preview:hover {
  opacity: 0.7;
}
/*LIGHT GALLERY */
.lg-backdrop {
  background-color: rgba(128, 122, 109, 0.85);
  z-index: 3900;
}
.lg-outer {
  z-index: 4000;
}
.lg-outer .lg-item {
  display: block;
}
/* CONTATTI */
#contatti h5 {
  color: #072d1d;
  font-weight: 600;
}
#contatti p {
  font-size: 12px;
}
#contatti ul.info {
  padding: 15px 0;
}
#contatti ul.info li {
  list-style: none;
  padding-bottom: 10px;
  color: #072d1d;
}
#contatti ul.info li i.fa {
  padding-right: 5px;
  color: #072d1d;
  width: 20px;
  text-align: center;
}
#contatti ul.info li a {
  color: #072d1d;
}
#contatti ul.info li a:hover {
  color: #fecb6e;
}
.form-group {
  margin-bottom: 0;
}
.form-group .form-control {
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #e0d7c0;
  color: #807a6d;
  font-size: 12px;
  -webkit-box-shadow: none;
          box-shadow: none;
  margin-bottom: 5px;
  padding: 12.5px;
  height: 44px;
}
.form-group .form-control:focus {
  border: 1px solid #e7e0ce;
  outline: 0;
  -webkit-box-shadow: inset 0px 0px 7px #e7e0ce;
          box-shadow: inset 0px 0px 7px #e7e0ce;
}
.form-group textarea.form-control {
  height: auto;
}
/* Mappa */
#map .para_sec {
  height: 330px !important;
  background: #fecb6e;
}
#map #map-canvas {
  width: 100%;
  height: 100%;
}
/* PAGINE INTERNE */
.page {
  padding-top: 85px;
  text-align: left;
}
.page .title {
  margin: 0;
}
.page .text {
  font-size: 20px;
  line-height: 32px;
}
/* CAROUSEL FADE */
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
